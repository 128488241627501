import React from 'react';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import StandardModal from '~/components/Modal';
import RoomForm from './roomForm';

export default function UpdateRoomDialog({ closeCallback }) {
  const { params: roomInfo } = useSelector((state) => state.modal);

  return (
    <StandardModal
      title="Atualizar Sala"
      onClose={closeCallback}
      isOpened
      maxWidth="sm"
      showDividers={false}
      slideTo="left"
      fullWidth
    >
      <Box
        width={1}
        className="h-100"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        pt={5}
      >
        <RoomForm roomInfo={roomInfo} handleClose={closeCallback} />
      </Box>
    </StandardModal>
  );
}

UpdateRoomDialog.propTypes = {
  closeCallback: Proptypes.func.isRequired,
};
