import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import {
  Container,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputBase,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';

import {
  Autorenew as RenewIcon,
  FileCopy as CopyIcon,
  Edit as EditIcon,
  Search as SearchIcon,
  Delete as TrashIcon,
  Send as SendIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { Creators as ModalActions } from '~/store/modules/modal/ducks';
import { Creators as UsersActions } from '~/store/modules/users/ducks';

import AddUserModal from './addUser';
import UpdateUserModal from './updateUser';
import DestroyUserModal from './destroyUser';
import { Creators as TagsActions } from '~/store/modules/tag/ducks';
import ImportUserDialog from './importUser';
import SendTokenModal from '~/pages/Users/sendTokenModal';
import { StyledTableContainer } from './styles';
import BulkUserTags from './bulkTags';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function Users() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ADD_USER_MODAL = 'ADD_USER';
  const UPDATE_USER_MODAL = 'UPDATE_USER';
  const IMPORT_MODAL = 'IMPORT_USER_MODAL';
  const BULK_USER_CONFIRMATION = 'BULK_USER_CONFIRMATION';
  const activeModal = useSelector((state) => state.modal.active);
  const userList = useSelector((state) => state.users.list);
  const listFilters = useSelector((state) => state.users.filters);
  const listTotal = useSelector((state) => state.users.listTotal);
  const tagsList = useSelector((state) => state.tag.list);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [bulkTagId, setBulkTagId] = useState(0);
  const rowsPerPage = 5;

  useEffect(() => {
    dispatch(UsersActions.userListRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(TagsActions.tagListRequest());
    return () => {
      dispatch(UsersActions.resetFilter());
    };
  }, []);

  const handleCloseModal = () => {
    dispatch(UsersActions.userListRequest());
    dispatch(ModalActions.closeModal());
  };

  const handleOpenUserModal = () => {
    dispatch(ModalActions.openModal(ADD_USER_MODAL));
  };

  const handleOpenImportParticipant = () => {
    dispatch(ModalActions.openModal(IMPORT_MODAL));
  };

  const handleOpenUpdateModal = (user) => {
    dispatch(ModalActions.openModal({ name: UPDATE_USER_MODAL, params: user }));
  };

  const handleCopy = (userToken) => {
    copy(userToken);
    toast.success('Token copiado.');
  };

  const toggleUser = (userId) => {
    dispatch(UsersActions.toggleUser(userId));
  };

  const renewToken = (userId) => {
    dispatch(UsersActions.renewUserToken(userId));
  };

  const handleFilter = ({ name, value }) => {
    dispatch(UsersActions.setUserFilter({ name, value }));
  };

  const fetchUsers = () => {
    dispatch(UsersActions.userListRequest());
  };

  const handleUserBytagListRequest = ({ value }) => {
    handleFilter({ name: 'tag_id', value });
    fetchUsers();
  };

  const handleNextPage = (event, value) => {
    handleFilter({ name: 'page', value });
    fetchUsers();
  };

  const openDestroyModal = (user) => {
    dispatch(
      ModalActions.openModal({ name: 'DELETE_USER_CONFIRMATION', params: user })
    );
  };

  const openSendTokenModal = (user) => {
    dispatch(
      ModalActions.openModal({
        name: 'SEND_USER_TOKEN_CONFIRMATION',
        params: user,
      })
    );
  };

  const handleAddToSelection = (user) => {
    setSelectedUsers([...selectedUsers, user]);
  };

  const removeFromSelectedList = (selectedUser) => {
    setSelectedUsers(
      selectedUsers.filter((user) => user.id !== selectedUser.id)
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleBulkTagChange = (selectedTag) => {
    setBulkTagId(selectedTag);
  };

  const clearUsersSelected = () => {
    setSelectedUsers([]);
  };

  const handleAddBulk = () => {
    if (!selectedUsers.length || !!bulkTagId.value) {
      const [selectedTag] = tagsList.filter(
        (tag) => bulkTagId.value === tag.id
      );
      return dispatch(
        ModalActions.openModal({
          name: 'BULK_USER_CONFIRMATION',
          params: {
            users: selectedUsers,
            tag: selectedTag,
          },
        })
      );
    }

    return toast.error('Selecione uma tag ou adicione algum participante!');
  };

  return (
    <>
      {activeModal === UPDATE_USER_MODAL && (
        <UpdateUserModal closeCallback={handleCloseModal} />
      )}
      {activeModal === ADD_USER_MODAL && (
        <AddUserModal closeCallback={handleCloseModal} />
      )}
      {activeModal === IMPORT_MODAL && (
        <ImportUserDialog closeCallback={handleCloseModal} />
      )}
      {activeModal === BULK_USER_CONFIRMATION && (
        <BulkUserTags
          clearUsersSelected={clearUsersSelected}
          closeCallback={handleCloseModal}
        />
      )}
      <Container>
        {!!selectedUsers.length && (
          <Box pt={3}>
            <StyledTableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead row>
                  <TableRow>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Tag</TableCell>
                    <TableCell align="right">Remover da lista</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => (
                      <TableRow key={user.id}>
                        <TableCell component="th" scope="row" align="center">
                          {user.name}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.tag.name}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => removeFromSelectedList(user)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              component="div"
              count={selectedUsers.length}
              rowsPerPageOptions={[10]}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
            />
            <Paper component={Box} p={2}>
              <Grid container justify="space-around" alignItems="center">
                <Grid item xs={3}>
                  <TextField
                    select
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="tag_id"
                    label="Selecione a Tag"
                    value={bulkTagId.value}
                    onChange={({ target }) => handleBulkTagChange(target)}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value={0}>Selecione uma Tag</MenuItem>
                    {!!tagsList.length &&
                      tagsList.map((tag) => (
                        <MenuItem value={tag.id}>{tag.name}</MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => handleAddBulk()}
                    size="large"
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Alterar Tags
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )}
        <Box my={2} display="flex" justifyContent="space-between">
          <Box display="flex" width={650} justifyContent="flex-start">
            <Box width="65%">
              <Paper
                variant="outlined"
                component="form"
                className={classes.root}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <InputBase
                  name="query"
                  placeholder="Digite o nome/email do Participante..."
                  onChange={({ target }) => handleFilter(target)}
                  className={classes.input}
                  value={listFilters.query}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <Tooltip title="Buscar por nome" placement="top">
                  <IconButton
                    onClick={fetchUsers}
                    color="primary"
                    className={classes.iconButton}
                    aria-label="directions"
                  >
                    <SearchIcon size="24" />
                  </IconButton>
                </Tooltip>
              </Paper>
            </Box>
            <Box width="35%">
              <TextField
                select
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                name="tag_id"
                label="Selecione a Tag"
                value={listFilters.tag_id}
                onChange={({ target }) => handleUserBytagListRequest(target)}
                variant="outlined"
                fullWidth
              >
                <MenuItem value={0}>Todas as Tags</MenuItem>
                {!!tagsList.length &&
                  tagsList.map((tag) => (
                    <MenuItem value={tag.id}>{tag.name}</MenuItem>
                  ))}
              </TextField>
            </Box>
          </Box>
          <Box px={1}>
            <Button
              onClick={handleOpenImportParticipant}
              size="large"
              variant="contained"
              color="primary"
              disableElevation
            >
              Importar Lista
            </Button>
          </Box>
          <Box px={1}>
            <Button
              onClick={handleOpenUserModal}
              size="large"
              variant="contained"
              color="primary"
              disableElevation
            >
              Adicionar Participante
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          width={1}
          flexDirection="column"
          justifyContent="flex-start"
        >
          {!!userList.length &&
            userList
              .filter(
                (user) =>
                  !selectedUsers.find(
                    (selectedUser) => user.id === selectedUser.id
                  )
              )
              .map((user) => (
                <Paper key={user.id} component={Box} width={1} mb={2}>
                  <Box
                    p={3}
                    width={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={1}>
                        <Box width={1}>
                          <Checkbox
                            onChange={() => handleAddToSelection(user)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box width={1}>
                          <Typography variant="h6">{user.name}</Typography>
                          <Typography variant="body2">{user.email}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          width={1}
                          height={1}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={user.active}
                                onChange={() => toggleUser(user.id)}
                                name="active"
                                color="primary"
                              />
                            }
                            label="Ativo"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          width={1}
                          height={1}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Paper
                            variant="outlined"
                            component="form"
                            className={classes.root}
                          >
                            <InputBase
                              className={classes.input}
                              value={user.token}
                              readOnly
                            />
                            <Tooltip title="Copiar Token" placement="top">
                              <IconButton
                                onClick={() => handleCopy(user.token)}
                                className={classes.iconButton}
                                aria-label="search"
                              >
                                <CopyIcon size="24" />
                              </IconButton>
                            </Tooltip>
                            <Divider
                              className={classes.divider}
                              orientation="vertical"
                            />
                            <Tooltip title="Renovar Token" placement="top">
                              <IconButton
                                onClick={() => renewToken(user.id)}
                                color="primary"
                                className={classes.iconButton}
                                aria-label="directions"
                              >
                                <RenewIcon size="24" />
                              </IconButton>
                            </Tooltip>
                            <Divider
                              className={classes.divider}
                              orientation="vertical"
                            />
                            <Tooltip title="Reenviar Token" placement="top">
                              <IconButton
                                onClick={() => openSendTokenModal(user)}
                                color="primary"
                                className={classes.iconButton}
                                aria-label="send"
                              >
                                <SendIcon size="24" />
                              </IconButton>
                            </Tooltip>
                          </Paper>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          width={1}
                          height={1}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Box mr={1}>
                            <Button
                              onClick={() => handleOpenUpdateModal(user)}
                              variant="outlined"
                              startIcon={<EditIcon size="24" />}
                            >
                              Editar
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              onClick={() => openDestroyModal(user)}
                              variant="outlined"
                              color="secondary"
                              startIcon={<TrashIcon size="24" />}
                            >
                              Excluir
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              ))}
        </Box>

        {userList && !!userList.length && (
          <Box display="flex" justifyContent="center" flexDirection="row" m={2}>
            <Pagination
              page={listFilters.page}
              count={Math.ceil(listTotal / 10)}
              onChange={handleNextPage}
              color="primary"
              shape="rounded"
              size="large"
            />
          </Box>
        )}
      </Container>

      <DestroyUserModal />
      <SendTokenModal />
    </>
  );
}
