import React from 'react';
import Proptypes from 'prop-types';

import MultiType from './multiType';

function LaunchItemKind({ kind, setChoices }) {
  return (
    <>
      {kind && kind !== 'Evidence' && (
        <MultiType kind={kind} setChoices={setChoices} />
      )}
    </>
  );
}

LaunchItemKind.propTypes = {
  kind: Proptypes.string.isRequired,
  setChoices: Proptypes.func.isRequired,
};

export default LaunchItemKind;
