import {
  Accordion,
  AccordionSummary,
  List,
  ListItem,
  TableCell,
} from '@material-ui/core';
import styled from 'styled-components';

export const AccordionColumns = styled.div`
  flex-basis: 33.33%;
`;

export const AccordionColumnsHeader = styled.div`
  flex-basis: 33.33%;
  text-align: center;

  :nth-child(1) {
    text-align: left;
  }
  :nth-child(4) {
    text-align: end;
  }
  :nth-child(5) {
    text-align: end;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  opacity: 100% !important;
  font-weight: bold;
`;

export const IconTableCell = styled(TableCell)`
  svg:hover {
    cursor: pointer;
  }
`;

export const StyledList = styled(List)`
  padding: 0 !important;
  overflow-y: auto;
  height: 70vh;

  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .MuiAccordion-rounded:first-child {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`;

export const StyledListItem = styled(ListItem)`
  padding: 0 !important;
  padding-bottom: 10px !important;

  :nth-child(1) {
    padding-top: 10px !important;
  }
`;

export const StyledAccordion = styled(Accordion)`
  width: 100%;
`;
