import { call, put, all, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Creators as UsersActions, Types as UsersTypes } from './ducks';

const getUsersList = (state) => state.users.list;
const getListTotal = (state) => state.users.listTotal;
const getUsersListFilters = (state) => state.users.filters;

export function* fetchUsers() {
  let listFilters = yield select(getUsersListFilters);
  if (listFilters.query !== listFilters.lastQuery) {
    yield put(UsersActions.setUserFilter({ name: 'page', value: 1 }));
    listFilters = yield select(getUsersListFilters);
  }
  const filters = Object.entries(listFilters).map(
    ([key, value]) => `${key}=${encodeURIComponent(value)}`
  );
  const textFilters = filters.join('&');

  try {
    const { data } = yield call(api.get, `/users?${textFilters}`);
    yield put(UsersActions.userListSuccess(data.users));
    yield put(UsersActions.userListTotal(data.total));
    yield put(
      UsersActions.setUserFilter({
        name: 'lastQuery',
        value: listFilters.query,
      })
    );
    yield put(UsersActions.setUserFilter({ name: 'page', value: data.page }));
  } catch (response) {
    yield put(
      UsersActions.userListFailure('Não foi possível listar os participantes')
    );
    toast.error('Erro inesperado ao listar os participantes');
  }
}

export function* toggleUser({ userId }) {
  const usersList = yield select(getUsersList);
  const listTotal = yield select(getListTotal);

  try {
    const { data: updatedUser } = yield call(
      api.patch,
      `/users/${userId}/toggle`
    );

    const updatedUsers = usersList.map((user) => {
      if (user.id === updatedUser.id) {
        return updatedUser;
      }
      return user;
    });

    yield put(UsersActions.userListSuccess(updatedUsers));
    yield put(UsersActions.userListTotal(listTotal));

    toast.success('Usuário atualizado com sucesso');
  } catch (response) {
    toast.error('Erro inesperado ao atualizar o usuário');
  }
}

export function* sendUserToken({ userId }) {
  try {
    yield call(api.patch, `/users/${userId}/sendToken`);
    toast.success('Token enviado com sucesso');
  } catch (response) {
    toast.error('Erro inesperado ao enviar o token do usuário');
  }
}

export function* renewUserToken({ userId }) {
  const usersList = yield select(getUsersList);
  const listTotal = yield select(getListTotal);

  try {
    const { data: updatedUser } = yield call(
      api.patch,
      `/users/${userId}/renewToken`
    );

    const updatedUsers = usersList.map((user) => {
      if (user.id === updatedUser.id) {
        return updatedUser;
      }
      return user;
    });

    yield put(UsersActions.userListSuccess(updatedUsers));
    yield put(UsersActions.userListTotal(listTotal));

    toast.success('Token atualizado com sucesso');
  } catch (response) {
    toast.error('Erro inesperado ao atualizar o token do usuário');
  }
}

export function* importUsers({ request }) {
  try {
    yield call(api.post, `import/users`, request);
    yield put(UsersActions.userImportSuccess('Importação enviada com sucesso'));
    toast.success('Importação enviada com sucesso');
  } catch ({ response }) {
    let error = 'Não foi possível atualizar o participante';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);
    yield put(UsersActions.userImportFailure(error));
  }
}

export function* destroyUser({ payload }) {
  try {
    yield call(api.delete, `/users/${payload}`);
    yield put(UsersActions.userDestroySuccess());
    yield put(UsersActions.userListRequest());
    toast.success('Usuário deletado com sucesso.');
  } catch (error) {
    yield put(UsersActions.userDestroyFailure(error));
    toast.error(error);
  }
}

export function* bulkChangeTags({ payload }) {
  try {
    yield call(api.post, `/users/bulkTag`, payload);
    yield put(UsersActions.userListRequest());
    yield put(UsersActions.usersBulkTagChangeSuccess());
    toast.success('Usuários atualizados com sucesso.');
  } catch (error) {
    yield put(UsersActions.usersBulkTagChangeFailure(error));
    toast.error(error);
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(UsersTypes.USER_LIST_REQUEST, fetchUsers),
    takeLatest(UsersTypes.TOGGLE_USER, toggleUser),
    takeLatest(UsersTypes.RENEW_USER_TOKEN, renewUserToken),
    takeLatest(UsersTypes.SEND_USER_TOKEN, sendUserToken),
    takeLatest(UsersTypes.USER_IMPORT_REQUEST, importUsers),
    takeLatest(UsersTypes.USER_DESTROY_REQUEST, destroyUser),
    takeLatest(UsersTypes.USERS_BULK_TAG_CHANGE_REQUEST, bulkChangeTags),
  ]);
}
