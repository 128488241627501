import React, { useState } from 'react';
import { Box, Slide } from '@material-ui/core';
import QuestionStep from './steps/question';
import QuestionItemsStep from './steps/items';
import QuestionContext from './index.context';

function QuestionMain() {
  const [currentStep, setCurrentStep] = useState('question');

  const setStep = (step) => {
    setCurrentStep(step);
  };

  return (
    <QuestionContext.Provider
      value={{
        currentStep,
        setStep,
      }}
    >
      {currentStep === 'question' && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <Box width={1}>
            <QuestionStep />
          </Box>
        </Slide>
      )}
      {currentStep === 'items' && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <Box width={1}>
            <QuestionItemsStep />
          </Box>
        </Slide>
      )}
    </QuestionContext.Provider>
  );
}

export default QuestionMain;
