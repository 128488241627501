import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  error: '',
  success: '',
};

export const { Types, Creators } = createActions({
  userCreateRequest: ['payload'],
  userUpdateRequest: ['payload'],
  userSuccess: ['success'],
  userFailure: ['error'],
});

function userCreateRequest(state) {
  return { ...state, loading: true };
}

function userUpdateRequest(state) {
  return { ...state, loading: true };
}

function userSuccess(state, { success }) {
  return {
    ...state,
    loading: false,
    current: { ...INITIAL_STATE.current },
    success,
  };
}

function userFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.USER_CREATE_REQUEST]: userCreateRequest,
  [Types.USER_UPDATE_REQUEST]: userUpdateRequest,
  [Types.USER_SUCCESS]: userSuccess,
  [Types.USER_FAILURE]: userFailure,
});
