import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, Grid, TextField } from '@material-ui/core';

import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'; // choose your lib
import {
  MobileDateTimePicker,
  LocalizationProvider,
} from '@material-ui/pickers';
import ptBrLocale from 'date-fns/locale/pt-BR';

import { Creators as ComponentActions } from '~/store/modules/component/ducks';

import QuestionContext from '../index.context';

export default function QuestionsComponent() {
  const dispatch = useDispatch();
  const { setStep } = useContext(QuestionContext);
  const currentComponent = useSelector((state) => state.component.current);
  const { question } = currentComponent;

  const setData = ({ name, value }) => {
    dispatch(
      ComponentActions.setComponentKindData({ kind: 'question', name, value })
    );
  };

  const handleDateChange = (value) => {
    setData({ name: 'period', value });
  };

  return (
    <Container>
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="title"
              variant="outlined"
              label="Categoria das Perguntas"
              placeholder="Ex: Dia 1"
              InputLabelProps={{
                shrink: true,
              }}
              value={question.title}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider
              locale={ptBrLocale}
              dateAdapter={DateFnsAdapter}
            >
              <MobileDateTimePicker
                renderInput={(props) => (
                  <TextField
                    variant="outlined"
                    {...props}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText=""
                    fullWidth
                  />
                )}
                ampm={false}
                disablePast
                okText="Confirmar"
                cancelText="Cancelar"
                label="Data e Hora de Início"
                value={question.period}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Box display="flex" pb={2} justifyContent="flex-end">
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => setStep('items')}
            style={{ height: '55px' }}
          >
            Adicionar Perguntas
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
