import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  error: '',
  top100: [],
  scoreDetail: [],
  scoreKindDetails: [],
};

export const { Types, Creators } = createActions({
  top100Request: [''],
  top100Success: ['data'],
  top100Failure: ['error'],
  top100ScoresRequest: ['participantId'],
  top100ScoresSuccess: ['payload'],
  top100ScoresFailure: ['error'],
  top100ScoresDetailsRequest: ['options'],
  top100ScoresDetailsSuccess: ['payload'],
  top100ScoresDetailsFailure: ['error'],
  top100ScoresDetailsReset: [''],
});

function top100Request(state) {
  return { ...state, loading: true };
}

function top100Success(state, { data }) {
  return {
    ...state,
    loading: false,
    top100: data,
  };
}

function top100Failure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function top100ScoresRequest(state, { participantId }) {
  return { ...state, participantId, loading: true };
}

function top100ScoresSuccess(state, { payload }) {
  return {
    ...state,
    loading: false,
    scoreDetail: payload,
  };
}

function top100ScoresFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function top100ScoresDetailsRequest(state) {
  return { ...state, loading: true };
}

function top100ScoresDetailsSuccess(state, { payload }) {
  return {
    ...state,
    loading: false,
    scoreKindDetails: payload,
  };
}

function top100ScoresDetailsFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function top100ScoresDetailsReset(state, { error }) {
  return {
    ...state,
    loading: false,
    scoreKindDetails: [],
    error,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.TOP100_REQUEST]: top100Request,
  [Types.TOP100_SUCCESS]: top100Success,
  [Types.TOP100_FAILURE]: top100Failure,
  [Types.TOP100_SCORES_REQUEST]: top100ScoresRequest,
  [Types.TOP100_SCORES_SUCCESS]: top100ScoresSuccess,
  [Types.TOP100_SCORES_FAILURE]: top100ScoresFailure,
  [Types.TOP100_SCORES_DETAILS_REQUEST]: top100ScoresDetailsRequest,
  [Types.TOP100_SCORES_DETAILS_SUCCESS]: top100ScoresDetailsSuccess,
  [Types.TOP100_SCORES_DETAILS_FAILURE]: top100ScoresDetailsFailure,
  [Types.TOP100_SCORES_DETAILS_RESET]: top100ScoresDetailsReset,
});
