import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import { useFormik } from 'formik';
import tagSchema from './tag.schema';

import { Creators as TagsActions } from '~/store/modules/tag/ducks';

export default function RoomForm({ tagInfo, handleClose, hasDefault }) {
  const dispatch = useDispatch();
  const createOrUpdate = tagInfo.id ? 'Atualizar' : 'Adicionar';

  useEffect(() => {
    dispatch(TagsActions.tagListRequest());
  }, [dispatch]);

  const handleSubmit = (formValues) => {
    const formFields = formValues;
    formFields.close = handleClose;
    formFields.is_default = !!Number(formValues.is_default);
    if (createOrUpdate === 'Atualizar') {
      formFields.id = tagInfo.id;
      return dispatch(TagsActions.tagUpdateRequest(formValues));
    }
    return dispatch(TagsActions.tagCreateRequest(formValues));
  };

  const formik = useFormik({
    initialValues: {
      name: tagInfo.name || '',
      is_default: '0',
    },
    validationSchema: tagSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const hasError = (fieldName) =>
    formik.touched[fieldName] && !!formik.errors[fieldName];

  const handleDefault = (event) => {
    formik.setFieldValue('is_default', event.value);
  };

  return (
    <Container maxWidth="xs">
      <Box mb={4}>
        <TextField
          name="name"
          variant="outlined"
          label="Nome"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={hasError('name')}
          onBlur={formik.handleBlur}
          helperText={hasError('name') ? formik.errors.name : null}
          fullWidth
        />
      </Box>

      {!hasDefault && (
        <Box mb={4}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tag padrão ?</FormLabel>
            <RadioGroup
              name="is_default"
              value={formik.values.is_default}
              onChange={(e) => handleDefault(e.target)}
              row
            >
              <FormControlLabel value="1" control={<Radio />} label="Sim" />
              <FormControlLabel value="0" control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Box>
      )}

      <Box mb={4}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {createOrUpdate}
        </Button>
      </Box>
    </Container>
  );
}

RoomForm.defaultProps = {
  tagInfo: {
    name: '',
  },
};

RoomForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  tagInfo: PropTypes.objectOf({
    name: PropTypes.string,
  }),
};
