import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  Creators as QuestionItemActions,
  Types as QuestionItemTypes,
} from './ducks';

export function* destroyQuestionItem({ payload }) {
  try {
    yield call(api.delete, `/questionItems/${payload.id}`);
    yield put(QuestionItemActions.questionItemSuccess());
    toast.success('Item deletado com sucesso');
  } catch (err) {
    yield put(QuestionItemActions.questionItemFailure(err.message));
    toast.error('Erro inesperado ao deletar o item');
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(QuestionItemTypes.DESTROY_REQUEST, destroyQuestionItem),
  ]);
}
