import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  Creators as ResourceItemActions,
  Types as ResourceItemTypes,
} from './ducks';
import { Creators as ComponentActions } from '../component/ducks';

export function* createResourceItem({ payload }) {
  try {
    const { data } = yield call(
      api.post,
      `/resourceItem`,
      payload.resourceItem
    );

    const { newList } = payload;
    newList.push(data);

    yield put(
      ComponentActions.setComponentKindData({
        kind: 'resource',
        name: 'items',
        value: newList,
      })
    );

    yield put(ResourceItemActions.resourceItemSuccess());
    toast.success('Item criado com sucesso');
  } catch (err) {
    yield put(ResourceItemActions.resourceItemFailure(err.message));
    toast.error('Erro inesperado ao criar o item');
  }
}

export function* destroyResourceItem({ payload }) {
  try {
    yield call(api.delete, `/resourceItem/${payload.id}`);
    yield put(ResourceItemActions.resourceItemSuccess());
    toast.success('Item deletado com sucesso');
  } catch (err) {
    yield put(ResourceItemActions.resourceItemFailure(err.message));
    toast.error('Erro inesperado ao deletar o item');
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(
      ResourceItemTypes.DESTROY_RESOURCE_ITEM_REQUEST,
      destroyResourceItem
    ),
    takeLatest(
      ResourceItemTypes.CREATE_RESOURCE_ITEM_REQUEST,
      createResourceItem
    ),
  ]);
}
