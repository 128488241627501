import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import styled from 'styled-components';

export const ColorPreviewDiv = styled.div`
  width: 36px;
  height: 14px;
  background: ${(props) => props.color};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`;

export const CustomPopover = styled(Popover)`
  pointer-events: none;
`;

export const colorButtonStyles = makeStyles({
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
    border: '1px solid #c4c4c4',
  },
  popoverPaper: {},
  popover: {
    pointerEvents: 'all',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
});
