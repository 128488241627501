import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  filters: {
    tag_id: 0,
  },
  list: [],
  loading: false,
  error: '',
};

export const { Types, Creators } = createActions({
  roomCreateRequest: ['payload'],
  roomCreateSuccess: ['success'],
  roomCreateFailure: ['error'],
  roomUpdateRequest: ['payload'],
  roomUpdateSuccess: ['success'],
  roomUpdateFailure: ['error'],
  roomDeleteRequest: ['payload'],
  roomDeleteSuccess: ['success'],
  roomDeleteFailure: ['error'],
  roomListRequest: ['payload'],
  roomListSuccess: ['list'],
  roomListFailure: ['error'],
});

function roomListRequest(state) {
  return { ...state, loading: true };
}

function roomListSuccess(state, { list }) {
  return {
    ...state,
    loading: false,
    list,
  };
}

function roomListFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function roomCreateRequest(state) {
  return { ...state, loading: true };
}

function roomCreateSuccess(state, { success }) {
  return {
    ...state,
    loading: false,
    success,
  };
}

function roomCreateFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function roomUpdateRequest(state) {
  return { ...state, loading: true };
}

function roomUpdateSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function roomUpdateFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function roomDeleteRequest(state) {
  return { ...state, loading: true };
}

function roomDeleteSuccess(state, { success }) {
  return {
    ...state,
    loading: false,
    success,
  };
}

function roomDeleteFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}
export default createReducer(INITIAL_STATE, {
  [Types.ROOM_LIST_REQUEST]: roomListRequest,
  [Types.ROOM_LIST_SUCCESS]: roomListSuccess,
  [Types.ROOM_LIST_FAILURE]: roomListFailure,
  [Types.ROOM_CREATE_REQUEST]: roomCreateRequest,
  [Types.ROOM_CREATE_SUCCESS]: roomCreateSuccess,
  [Types.ROOM_CREATE_FAILURE]: roomCreateFailure,
  [Types.ROOM_UPDATE_REQUEST]: roomUpdateRequest,
  [Types.ROOM_UPDATE_SUCCESS]: roomUpdateSuccess,
  [Types.ROOM_UPDATE_FAILURE]: roomUpdateFailure,
  [Types.ROOM_DELETE_REQUEST]: roomDeleteRequest,
  [Types.ROOM_DELETE_SUCCESS]: roomDeleteSuccess,
  [Types.ROOM_DELETE_FAILURE]: roomDeleteFailure,
});
