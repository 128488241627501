import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  error: '',
};

export const { Types, Creators } = createActions({
  launchPlanItemDestroyRequest: ['payload'],
  launchPlanItemAddRequest: ['payload'],
  launchPlanItemSuccess: [],
  launchPlanItemFailure: ['error'],
});

function launchPlanItemDestroyRequest(state) {
  return { ...state, loading: true };
}

function launchPlanItemAddRequest(state) {
  return { ...state, loading: true };
}

function launchPlanItemSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function launchPlanItemFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.LAUNCH_PLAN_ITEM_DESTROY_REQUEST]: launchPlanItemDestroyRequest,
  [Types.LAUNCH_PLAN_ITEM_ADD_REQUEST]: launchPlanItemAddRequest,
  [Types.LAUNCH_PLAN_ITEM_SUCCESS]: launchPlanItemSuccess,
  [Types.LAUNCH_PLAN_ITEM_FAILURE]: launchPlanItemFailure,
});
