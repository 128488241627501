import { all } from 'redux-saga/effects';

import authSagas from './auth/sagas';
import userSagas from './user/sagas';
import usersSagas from './users/sagas';
import tag from './tag/sagas';
import componentSagas from './component/sagas';
import conferenceSagas from './conference/sagas';
import rankingSagas from './ranking/sagas';
import roomsSagas from './rooms/sagas';
import pointsSagas from './points/sagas';
import questionItemSagas from './questionItem/sagas';
import resourceItemSagas from './resourceItem/sagas';
import launchPlanItemSagas from './launchPlanItem/sagas';

export default function* rootSaga() {
  return yield all([
    authSagas(),
    tag(),
    componentSagas(),
    userSagas(),
    usersSagas(),
    conferenceSagas(),
    rankingSagas(),
    roomsSagas(),
    pointsSagas(),
    questionItemSagas(),
    resourceItemSagas(),
    launchPlanItemSagas(),
  ]);
}
