import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { ColorPreviewDiv, colorButtonStyles, CustomPopover } from './styles';
import useOutsideClick from '~/util/useOutsideClick';

export default function ColorPickerButton({ handleColorChange, color }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (displayColorPicker) {
      setDisplayColorPicker(false);
    }
  });

  const handleClick = (target) => {
    setDisplayColorPicker(!displayColorPicker);
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = colorButtonStyles();

  return (
    <div>
      <button
        type="button"
        className={classes.swatch}
        onClick={(e) => handleClick(e.currentTarget)}
      >
        <ColorPreviewDiv color={color} />
      </button>

      <CustomPopover
        ref={ref}
        open={displayColorPicker}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: classes.popoverPaper,
        }}
      >
        <ChromePicker
          color={color}
          onChange={(newColor) => handleColorChange(newColor.hex)}
          className={classes.popover}
        />
      </CustomPopover>
    </div>
  );
}

ColorPickerButton.propTypes = {
  handleColorChange: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};
