import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  error: '',
};

export const { Types, Creators } = createActions({
  createResourceItemRequest: ['payload'],
  destroyResourceItemRequest: ['payload'],
  resourceItemSuccess: [],
  resourceItemFailure: ['error'],
});

function createResourceItemRequest(state) {
  return { ...state, loading: true };
}

function destroyResourceItemRequest(state) {
  return { ...state, loading: true };
}

function resourceItemSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function resourceItemFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.CREATE_RESOURCE_ITEM_REQUEST]: createResourceItemRequest,
  [Types.DESTROY_RESOURCE_ITEM_REQUEST]: destroyResourceItemRequest,
  [Types.RESOURCE_ITEM_SUCCESS]: resourceItemSuccess,
  [Types.RESOURCE_ITEM_FAILURE]: resourceItemFailure,
});
