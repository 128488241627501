import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { Creators as RoomActions, Types as RoomTypes } from './ducks';

export function* fetchAllRooms() {
  try {
    const { data } = yield call(api.get, `conferenceRooms`);
    yield put(RoomActions.roomListSuccess(data.conferenceRooms));
  } catch ({ response }) {
    yield put(
      RoomActions.roomListRequestFailure({
        error: 'Falha ao buscar lista de salas',
      })
    );
  }
}

export function* createRoom({ payload }) {
  try {
    yield call(api.post, '/conferenceRooms', payload);
    yield put(RoomActions.roomCreateSuccess('criado com sucesso'));
    toast.success('Sala criada com sucesso');
    payload.close();
  } catch ({ response }) {
    let error = 'Não foi possível criar sala';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);

    yield put(RoomActions.roomCreateFailure(error));
  }
}

export function* updateRoom({ payload }) {
  try {
    yield call(api.patch, `/conferenceRooms/${payload.id}`, payload);
    yield put(RoomActions.roomUpdateSuccess());
    toast.success('Sala atualizada com sucesso');
    payload.close();
  } catch ({ response }) {
    let error = 'Não foi possível atualizar a sala';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);

    yield put(RoomActions.roomUpdateFailure(error));
  }
}

export function* deleteRoom({ payload }) {
  try {
    yield call(api.delete, `/conferenceRooms/${payload.id}`);
    yield put(RoomActions.roomDeleteSuccess('Excluído com sucesso'));
    payload.close();
    toast.success('Sala excluída com sucesso');
  } catch (response) {
    yield put(RoomActions.roomDeleteFailure());

    toast.error('Erro inesperado ao excluir a sala');
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(RoomTypes.ROOM_CREATE_REQUEST, createRoom),
    takeLatest(RoomTypes.ROOM_LIST_REQUEST, fetchAllRooms),
    takeLatest(RoomTypes.ROOM_UPDATE_REQUEST, updateRoom),
    takeLatest(RoomTypes.ROOM_DELETE_REQUEST, deleteRoom),
  ]);
}
