import React from 'react';
import Proptypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, Divider, Typography } from '@material-ui/core';
import { Creators as ComponentActions } from '~/store/modules/component/ducks';

import StandardModal from '~/components/Modal';

export default function DeleteComponentModal({ closeCallback }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.component.loading);
  const currentComponent = useSelector((state) => state.component.current);

  const handleClose = () => {
    closeCallback();
  };

  const handleDeleteComponent = async () => {
    dispatch(ComponentActions.componentDeleteRequest({ close: handleClose }));
  };

  return (
    <StandardModal
      title="Excluir Componente"
      onClose={handleClose}
      isOpened
      maxWidth="sm"
      showDividers
      actionElements={
        <Box display="flex" p={2}>
          <Box px={2}>
            <Button onClick={handleClose}>Cancelar</Button>
          </Box>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={handleDeleteComponent}
            disabled={loading}
          >
            Excluir Agora
          </Button>
        </Box>
      }
      slideTo="left"
      fullWidth
    >
      <Box
        className="h-100"
        display="flex"
        alignItems="center"
        justifyContent="center"
        pt={5}
      >
        <Container maxWidth="xs">
          <Box mb={4}>
            <Typography variant="h5">
              Deseja realmente excluir o componente a seguir?
            </Typography>
            <br />
            <Typography variant="h6" color="primary">
              {currentComponent.name}
            </Typography>
            <Divider />
            <br />
            <Typography variant="subtitle1">
              Obs: Todos os dados de configuração do componente como textos e
              datas serão excluídos.
            </Typography>
          </Box>
          <Box
            mb={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          />
        </Container>
      </Box>
    </StandardModal>
  );
}

DeleteComponentModal.propTypes = {
  closeCallback: Proptypes.func.isRequired,
};
