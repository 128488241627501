import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  InputBase,
  ListItem,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DeleteForever as DeleteIcon,
  Edit as EditIcon,
  FileCopy as CopyIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { Creators as RoomActions } from '~/store/modules/rooms/ducks';
import { Creators as ModalActions } from '~/store/modules/modal/ducks';
import AddRoomModal from './addRoom';
import UpdateRoomModal from './updateRoom';
import DeleteRoomModal from './roomDelete';

export default function Rooms() {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '95%',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    actionGrid: {
      display: 'flex',
      'align-content': 'center',
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const ADD_ROOM_MODAL = 'ADD_ROOM_MODAL';
  const UPDATE_ROOM_MODAL = 'UPDATE_ROOM_MODAL';
  const DELETE_ROOM_MODAL = 'DELETE_ROOM_MODAL';
  const activeModal = useSelector((state) => state.modal.active);
  const roomList = useSelector((state) => state.room.list);

  const handleOpenRoomModal = () => {
    dispatch(ModalActions.openModal({ name: ADD_ROOM_MODAL }));
  };

  const handleOpenUpdateRoomModal = (room) => {
    dispatch(ModalActions.openModal({ name: UPDATE_ROOM_MODAL, params: room }));
  };

  const handleOpenDeleteComponentModal = (room) => {
    dispatch(ModalActions.openModal({ name: DELETE_ROOM_MODAL, params: room }));
  };

  const handleCloseModal = () => {
    dispatch(RoomActions.roomListRequest());
    dispatch(ModalActions.closeModal());
  };

  const handleCopy = (url) => {
    copy(url);
    toast.success('Url copiada.');
  };

  useEffect(() => {
    dispatch(RoomActions.roomListRequest());
  }, []);

  return (
    <>
      {activeModal === ADD_ROOM_MODAL && (
        <AddRoomModal closeCallback={handleCloseModal} />
      )}

      {activeModal === UPDATE_ROOM_MODAL && (
        <UpdateRoomModal closeCallback={handleCloseModal} />
      )}

      {activeModal === DELETE_ROOM_MODAL && (
        <DeleteRoomModal closeCallback={handleCloseModal} />
      )}

      <Container>
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box
              my={1}
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box>
                <Button
                  onClick={handleOpenRoomModal}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Criar Sala
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} mZd={12}>
            <Box
              display="flex"
              my={2}
              width={1}
              flexDirection="column"
              justifyContent="flex-start"
            >
              <Paper component={Box} width={1} mb={2} flexDirection="column">
                <Box
                  width={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {!!roomList.length &&
                    roomList.map((room) => (
                      <ListItem divider>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <Typography>{room.name}</Typography>
                            <Chip size="small" label={room.tag.name} />
                          </Grid>
                          <Grid item xs={8}>
                            <Paper
                              variant="outlined"
                              component="form"
                              className={classes.root}
                            >
                              <InputBase
                                className={classes.input}
                                value={room.link}
                                readOnly
                              />
                              <Tooltip title="Copiar Url" placement="top">
                                <IconButton
                                  onClick={() => handleCopy(room.link)}
                                  className={classes.iconButton}
                                  aria-label="search"
                                >
                                  <CopyIcon size="24" />
                                </IconButton>
                              </Tooltip>
                            </Paper>
                          </Grid>
                          <Grid item xs={2} className={classes.actionGrid}>
                            <Box
                              width={1}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Button
                                onClick={() => {
                                  handleOpenUpdateRoomModal(room);
                                }}
                                size="small"
                                startIcon={<EditIcon size="24" />}
                              >
                                Editar
                              </Button>

                              <Button
                                onClick={() => {
                                  handleOpenDeleteComponentModal(room);
                                }}
                                size="small"
                                startIcon={<DeleteIcon size="24" />}
                              >
                                Excluir
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
