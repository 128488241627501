import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Button, Container, MenuItem, TextField } from '@material-ui/core';

import { useFormik } from 'formik';
import roomSchema from './room.schema';

import { Creators as TagsActions } from '~/store/modules/tag/ducks';
import { Creators as RoomActions } from '~/store/modules/rooms/ducks';

export default function RoomForm({ roomInfo, handleClose }) {
  const dispatch = useDispatch();
  const tagsList = useSelector((state) => state.tag.list);
  const createOrUpdate = roomInfo.id ? 'Atualizar' : 'Adicionar';

  useEffect(() => {
    dispatch(TagsActions.tagListRequest());
  }, [dispatch]);

  const handleSubmit = (formValues) => {
    const formFields = formValues;
    formFields.close = handleClose;
    if (createOrUpdate === 'Atualizar') {
      formFields.id = roomInfo.id;
      return dispatch(RoomActions.roomUpdateRequest(formValues));
    }
    return dispatch(RoomActions.roomCreateRequest(formValues));
  };

  const formik = useFormik({
    initialValues: {
      name: roomInfo.name,
      link: roomInfo.link,
      tag_id: roomInfo.tag_id,
    },
    validationSchema: roomSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const hasError = (fieldName) =>
    formik.touched[fieldName] && !!formik.errors[fieldName];

  return (
    <Container maxWidth="xs">
      <Box mb={4}>
        <TextField
          name="name"
          variant="outlined"
          label="Nome"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={hasError('name')}
          onBlur={formik.handleBlur}
          helperText={hasError('name') ? formik.errors.name : null}
          fullWidth
        />
      </Box>
      <Box mb={4}>
        <TextField
          name="link"
          variant="outlined"
          label="Url"
          value={formik.values.link}
          onChange={formik.handleChange}
          error={hasError('link')}
          onBlur={formik.handleBlur}
          helperText={hasError('link') ? formik.errors.link : null}
          fullWidth
        />
      </Box>
      <Box mb={4}>
        <TextField
          select
          name="tag_id"
          label="Selecione a Tag"
          value={formik.values.tag_id}
          onChange={formik.handleChange}
          error={hasError('tag_id')}
          onBlur={formik.handleBlur}
          helperText={hasError('tag_id') ? formik.errors.tag_id : null}
          variant="outlined"
          fullWidth
        >
          {!!tagsList.length &&
            tagsList.map((tag) => (
              <MenuItem value={tag.id}>{tag.name}</MenuItem>
            ))}
        </TextField>
      </Box>
      <Box mb={4}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {createOrUpdate}
        </Button>
      </Box>
    </Container>
  );
}

RoomForm.defaultProps = {
  roomInfo: {
    name: '',
    link: '',
    tag_id: 1,
    active: true,
  },
};

RoomForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  roomInfo: PropTypes.objectOf({
    name: PropTypes.string,
    link: PropTypes.string,
    tag_id: PropTypes.number,
  }),
};
