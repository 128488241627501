import axios from 'axios';

const getToken = () => {
  const persistKey = 'persist:confadmin';
  const persistedObject = JSON.parse(localStorage.getItem(persistKey)) || '{}';
  const adminUser = persistedObject.adminUser || '{}';
  const { token } = JSON.parse(adminUser);
  return !!token ? token : '';
};

export default axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 3000,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
