import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { NavigateNext as NextIcon } from '@material-ui/icons';
import { useFormik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import schema from './validation/welcome';
import { Creators as TagsActions } from '~/store/modules/tag/ducks';
import WizardContext from '../index.context';
import { Creators as ComponentActions } from '~/store/modules/component/ducks';

export default function ComponentWelcomeStep() {
  const dispatch = useDispatch();
  const currentComponent = useSelector((state) => state.component.current);
  const { nextStep, isUpdating } = useContext(WizardContext);
  const tagsList = useSelector((state) => state.tag.list);
  const [radioPointOnClick, setRadioPointOnClick] = useState('0');
  const [radioSideButton, setRadioSideButton] = useState('0');
  const [radioPdfButton, setRadioPdfButton] = useState('0');
  const { pointEnabled } = useSelector((state) => state.conference.config);

  const currentComponentKind = () => {
    if (currentComponent.kind) {
      return currentComponent.kind;
    }

    if (currentComponent.affiliate_button) {
      return 'affiliate';
    }

    if (currentComponent.saleLetterId) {
      return 'sale_letter';
    }

    if (currentComponent.warmupRoomId) {
      return 'warmup_room';
    }

    if (currentComponent.conferenceButtonId) {
      return 'conference_button';
    }

    if (currentComponent.launchPlanId) {
      return 'launch_plan';
    }

    if (currentComponent.resourceId) {
      return 'resource';
    }

    if (currentComponent.countdownId) {
      return 'countdown';
    }

    if (currentComponent.questionId) {
      return 'question';
    }

    return '';
  };

  const setData = ({ name, value }) => {
    dispatch(ComponentActions.setComponentData({ name, value }));
  };

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      name: currentComponent.name,
      tags: currentComponent.tags,
      kind: currentComponentKind(),
      affiliate_button: currentComponent.affiliate_button,
      point_value: currentComponent.point_value,
      side_button: currentComponent.side_button,
      point_day_limit: currentComponent.point_day_limit,
      point_conference_limit: currentComponent.point_conference_limit,
      point_on_click: currentComponent.point_on_click,
      pdf_button: currentComponent.pdf_button,
    },
    onSubmit: (values) => {
      const entries = Object.entries(values);
      entries.forEach((item) => {
        const [name, value] = item;
        setData({ name, value });
      });
      nextStep();
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, []);

  useEffect(() => {
    setRadioPointOnClick(currentComponent.point_on_click ? '1' : '0');
    setRadioSideButton(currentComponent.side_button ? '1' : '0');
    setRadioPdfButton(currentComponent.pdf_button ? '1' : '0');
  }, [currentComponent]);

  const handleFocus = (event) => event.target.select();

  const handleChangePointOnClick = (e) => {
    const pointOnClick = e.target.value;
    setRadioPointOnClick(pointOnClick);
    const formikPointOnClick = pointOnClick === '1';
    formik.setFieldValue('point_on_click', formikPointOnClick, true);
  };

  const handleChangeSideButton = (e) => {
    const sideButton = e.target.value;
    setRadioSideButton(sideButton);
    const formikSideButton = sideButton === '1';
    if (formikSideButton) {
      setRadioPdfButton('0');
      formik.setFieldValue('pdf_button', false, true);
    }
    formik.setFieldValue('side_button', formikSideButton, true);
  };

  const handleChangePdfButton = (e) => {
    const pdfButton = e.target.value;
    setRadioPdfButton(pdfButton);
    const formikPdfButton = pdfButton === '1';
    if (formikPdfButton) {
      setRadioSideButton('0');
      formik.setFieldValue('side_button', false, true);
    }
    formik.setFieldValue('pdf_button', formikPdfButton, true);
  };

  const handleAffiliateButton = () => {
    formik.setFieldValue('affiliate_button', true, true);
  };

  useEffect(() => {
    dispatch(TagsActions.tagListRequest());
  }, [dispatch]);

  const onChangeIntInput = ({ name, value }) => {
    const isNumber = /^[0-9\b]+$/;
    if (value === '' || isNumber.test(value)) {
      formik.setFieldValue(name, value, true);
    }
  };

  const qtdDiaria = () => {
    return formik.values.point_value * formik.values.point_day_limit;
  };

  const qtdEvento = () => {
    return formik.values.point_value * formik.values.point_conference_limit;
  };

  const onChangeKind = ({ name, value }) => {
    if (value !== 'affiliate') {
      formik.setFieldValue('affiliate_button', false, true);
    }

    formik.setFieldValue('side_button', false, true);
    formik.setFieldValue('point_on_click', false, true);
    formik.setFieldValue('point_day_limit', 0, true);
    formik.setFieldValue('point_conference_limit', 0, true);
    formik.setFieldValue('point_value', 0, true);
    setRadioSideButton('0');
    setRadioPointOnClick('0');
    setRadioPdfButton('0');
    formik.setFieldValue(name, value, true);
  };

  return (
    <Box
      className="h-100"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      pt={5}
    >
      <Container maxWidth="xs">
        <Box mb={6}>
          <Typography variant="h5" color="textPrimary">
            Preencha os dados e clique em continuar
          </Typography>
        </Box>
        <Box mb={2}>
          <TextField
            onChange={formik.handleChange}
            variant="outlined"
            name="name"
            helperText={formik.errors.name}
            error={formik.errors.name}
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.name}
            label="Nome do Componente"
            fullWidth
          />
        </Box>
        <Box mb={2}>
          {!!tagsList.length && (
            <Autocomplete
              multiple
              name="tags"
              id="tags"
              options={tagsList}
              getOptionLabel={(option) => option.name}
              defaultValue={[tagsList[0]]}
              filterSelectedOptions
              value={formik.values.tags}
              onChange={(_, newValue) => {
                formik.setFieldValue('tags', newValue, true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  helperText={formik.errors.tags}
                  error={formik.errors.tags}
                  label="Tags"
                  placeholder="Selecione as Tags"
                  fullWidth
                />
              )}
            />
          )}
        </Box>
        <Box mb={2}>
          <TextField
            select
            name="kind"
            helperText={formik.errors.kind}
            error={formik.errors.kind}
            value={formik.values.kind}
            label="Tipo de Componente"
            disabled={isUpdating}
            onChange={({ target }) => onChangeKind(target)}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="countdown">Contagem Regressiva</MenuItem>
            <MenuItem value="sale_letter">Botão/Texto</MenuItem>
            <MenuItem value="question">Perguntas</MenuItem>
            <MenuItem value="resource">Recursos de Aula</MenuItem>
            <MenuItem value="affiliate" onClick={handleAffiliateButton}>
              Botão de Afiliado
            </MenuItem>
            <MenuItem value="conference_button">Botão do Evento</MenuItem>
            <MenuItem value="launch_plan">
              Módulo de Plano de Lançamento
            </MenuItem>
            <MenuItem value="warmup_room">Salas de Aquecimento</MenuItem>
          </TextField>
        </Box>
        <Box mb={2}>
          {(formik.values.kind === 'resource' ||
            formik.values.kind === 'sale_letter' ||
            formik.values.kind === 'conference_button') && (
            <FormControl component="fieldset">
              <FormLabel
                error={formik.errors.point_on_click}
                component="legend"
              >
                Pontuar ao clicar ?
              </FormLabel>
              <RadioGroup
                name="radioPointOnClick"
                value={radioPointOnClick}
                onChange={(e) => handleChangePointOnClick(e)}
                row
              >
                <FormControlLabel
                  disabled={!pointEnabled}
                  value="1"
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  disabled={!pointEnabled}
                  value="0"
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
              {formik.errors.point_on_click && (
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                  {formik.errors.point_on_click}
                </p>
              )}
            </FormControl>
          )}
        </Box>
        <Box mb={2}>
          {formik.values.kind === 'sale_letter' && (
            <FormControl component="fieldset">
              <FormLabel error={formik.errors.pdf_button} component="legend">
                Botão de download do plano?
              </FormLabel>
              <RadioGroup
                name="radioPdfButton"
                value={radioPdfButton}
                onChange={(e) => handleChangePdfButton(e)}
                row
              >
                <FormControlLabel
                  value="1"
                  disabled={formik.values.side_button}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value="0"
                  disabled={formik.values.side_button}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
              {formik.errors.pdf_button && (
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                  {formik.errors.pdf_button}
                </p>
              )}
            </FormControl>
          )}
        </Box>
        <Box mb={2}>
          {formik.values.kind === 'sale_letter' && (
            <FormControl component="fieldset">
              <FormLabel error={formik.errors.side_button} component="legend">
                Botão Lateral?
              </FormLabel>
              <RadioGroup
                name="radioSideButton"
                value={radioSideButton}
                onChange={(e) => handleChangeSideButton(e)}
                row
              >
                <FormControlLabel
                  value="1"
                  disabled={formik.values.pdf_button}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value="0"
                  disabled={formik.values.pdf_button}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
              {formik.errors.side_button && (
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                  {formik.errors.side_button}
                </p>
              )}
            </FormControl>
          )}
        </Box>
        {(formik.values.kind === 'sale_letter' ||
          formik.values.kind === 'conference_button' ||
          formik.values.kind === 'affiliate') && (
          <Box mb={2}>
            <TextField
              disabled={
                formik.values.kind === 'question' ||
                formik.values.kind === 'launch_plan' ||
                !pointEnabled
              }
              onChange={({ target }) => onChangeIntInput(target)}
              helperText={formik.errors.point_value}
              error={formik.errors.point_value}
              value={formik.values.point_value}
              onFocus={handleFocus}
              variant="outlined"
              name="point_value"
              label="Pontos"
              fullWidth
            />
          </Box>
        )}
        {(formik.values.kind === 'sale_letter' ||
          formik.values.kind === 'conference_button') && (
          <Box mb={2}>
            <TextField
              disabled={!pointEnabled}
              onChange={({ target }) => onChangeIntInput(target)}
              helperText={formik.errors.point_day_limit}
              error={formik.errors.point_day_limit}
              value={formik.values.point_day_limit}
              onFocus={handleFocus}
              variant="outlined"
              name="point_day_limit"
              label="Quantas vezes pontua por dia?"
              fullWidth
            />
            <FormHelperText id="standard-weight-helper-text">
              Total de pontos possíveis por dia: {qtdDiaria()}
            </FormHelperText>
          </Box>
        )}
        {(formik.values.kind === 'sale_letter' ||
          formik.values.kind === 'conference_button') && (
          <Box mb={2}>
            <TextField
              disabled={!pointEnabled}
              onChange={({ target }) => onChangeIntInput(target)}
              helperText={formik.errors.point_conference_limit}
              error={formik.errors.point_conference_limit}
              value={formik.values.point_conference_limit}
              onFocus={handleFocus}
              variant="outlined"
              name="point_conference_limit"
              label="Quantas vezes pontua durante o evento?"
              fullWidth
            />
            <FormHelperText id="standard-weight-helper-text">
              Total de pontos possíveis durante o evento: {qtdEvento()}
            </FormHelperText>
          </Box>
        )}
        <Box mb={2} display="flex" justifyContent="center">
          <Button
            endIcon={<NextIcon size="24" />}
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            disabled={!formik.isValid}
            onClick={formik.submitForm}
          >
            Continuar
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
