import React from 'react';
import { Box } from '@material-ui/core';

import { useSelector } from 'react-redux';
import StandardModal from '~/components/Modal';
import RoomForm from './tagForm';

export default function TagModal({ hasDefaultTag }) {
  const modalParams = useSelector((state) => state.modal.params);

  return (
    <StandardModal
      title={modalParams.update ? 'Atualizar Tag' : 'Nova Tag'}
      onClose={modalParams.callback}
      isOpened
      maxWidth="sm"
      showDividers={false}
      slideTo="left"
      fullWidth
    >
      <Box
        width={1}
        className="h-100"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        pt={5}
      >
        <RoomForm
          handleClose={modalParams.callback}
          tagInfo={modalParams.update}
          hasDefault={hasDefaultTag}
        />
      </Box>
    </StandardModal>
  );
}
