import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import Proptypes from 'prop-types';
import { format, parseISO } from 'date-fns';

export default function LaunchPlanTable({ scoreKindDetails }) {
  const formatDate = (date) => format(parseISO(date), 'dd/MM/yyyy');

  const handleTitle = (title) => {
    const temp = document.createElement('div');
    temp.innerHTML = title;
    const text = temp.textContent || temp.innerText;
    const finalText = text.length > 50 ? `${text.substr(0, 50 - 1)}...` : text;
    return finalText;
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center">Data de Pontuação</TableCell>
              <TableCell align="right">Pontuação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!scoreKindDetails &&
              !!scoreKindDetails.length &&
              scoreKindDetails.map((scoreDetail) => (
                <TableRow key={scoreDetail.id}>
                  <TableCell component="th" scope="row">
                    {handleTitle(
                      scoreDetail.launch_plan_answer.launchPlanItem.title
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(scoreDetail.created_at)}
                  </TableCell>
                  <TableCell align="right">{scoreDetail.point_value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

LaunchPlanTable.propTypes = {
  scoreKindDetails: Proptypes.array,
};

LaunchPlanTable.defaultProps = {
  scoreKindDetails: [],
};
