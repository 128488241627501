import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Creators as TagActions, Types as TagTypes } from './ducks';

export function* fetchTag() {
  try {
    const { data } = yield call(api.get, '/tags');
    yield put(TagActions.tagListSuccess(data.tags));
  } catch (response) {
    yield put(TagActions.tagListFailure('Não foi possível listar as tags'));
    toast.error('Erro inesperado ao listar as tags');
  }
}

export function* createTag({ formFields }) {
  try {
    yield call(api.post, '/tags', formFields);
    formFields.close();
    toast.success('Tag criada');
  } catch (response) {
    toast.error('Erro inesperado ao criar a tag');
  }
}

export function* updateTag({ formFields }) {
  try {
    yield call(api.patch, `/tag/${formFields.id}`, formFields);
    formFields.close();
    toast.success('Tag atualizada');
  } catch (response) {
    toast.error('Erro inesperado ao atualizar a tag');
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(TagTypes.TAG_LIST_REQUEST, fetchTag),
    takeLatest(TagTypes.TAG_CREATE_REQUEST, createTag),
    takeLatest(TagTypes.TAG_UPDATE_REQUEST, updateTag),
  ]);
}
