import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Close } from '@material-ui/icons';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core';

export const StyledCloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export default function StandardModal({
  actionElements,
  children,
  isOpened,
  onClose,
  showDividers,
  title,
  maxWidth,
  blockBackdrop,
  hideCloseButton,
  fullScreen,
  fullWidth,
  scroll,
}) {
  return (
    <Dialog
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpened}
      disableBackdropClick={blockBackdrop}
      disableEscapeKeyDown={blockBackdrop}
      scroll={scroll}
    >
      <DialogTitle onClose={onClose} align="center">
        <Typography variant="subtitle1" component="span">
          {title}
        </Typography>
        {!hideCloseButton ? (
          <StyledCloseIcon>
            <IconButton aria-label="close" onClick={onClose}>
              <Close size="18" />
            </IconButton>
          </StyledCloseIcon>
        ) : null}
      </DialogTitle>
      <DialogContent dividers={showDividers}>{children}</DialogContent>
      <DialogActions>{actionElements}</DialogActions>
    </Dialog>
  );
}

StandardModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func,
  actionElements: PropTypes.element,
  isOpened: PropTypes.bool,
  showDividers: PropTypes.bool,
  maxWidth: PropTypes.string,
  blockBackdrop: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  scroll: PropTypes.string,
};

StandardModal.defaultProps = {
  children: null,
  title: '',
  onClose: () => ({}),
  actionElements: null,
  isOpened: false,
  showDividers: true,
  maxWidth: 'md',
  blockBackdrop: false,
  hideCloseButton: false,
  fullScreen: false,
  fullWidth: false,
  scroll: 'body',
};
