import React, { useState } from 'react';
import { Box, Slide } from '@material-ui/core';
import ResourceItemsStep from './steps/items';
import ResourceStep from './steps/resource';
import ResourceContext from './index.context';

function QuestionMain() {
  const [currentStep, setCurrentStep] = useState('resource');

  const setStep = (step) => {
    setCurrentStep(step);
  };

  return (
    <ResourceContext.Provider
      value={{
        currentStep,
        setStep,
      }}
    >
      {currentStep === 'resource' && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <Box width={1}>
            <ResourceStep />
          </Box>
        </Slide>
      )}
      {currentStep === 'items' && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <Box width={1}>
            <ResourceItemsStep />
          </Box>
        </Slide>
      )}
    </ResourceContext.Provider>
  );
}

export default QuestionMain;
