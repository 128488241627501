import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  filters: {
    tag_id: 0,
    query: '',
    lastQuery: '',
    page: 1,
  },
  list: [],
  listTotal: 0,
  loading: false,
  error: '',
};

export const { Types, Creators } = createActions({
  userListRequest: ['payload'],
  userListTotal: ['listTotal'],
  userListSuccess: ['list'],
  userListFailure: ['error'],
  toggleUser: ['userId'],
  renewUserToken: ['userId'],
  sendUserToken: ['userId'],
  setUserFilter: ['payload'],
  resetFilter: ['payload'],
  userImportRequest: ['request'],
  userImportSuccess: ['payload'],
  userImportFailure: ['error'],
  userDestroyRequest: ['payload'],
  userDestroySuccess: [],
  userDestroyFailure: ['error'],
  usersBulkTagChangeRequest: ['payload'],
  usersBulkTagChangeSuccess: [],
  usersBulkTagChangeFailure: ['error'],
});

function toggleUser(state, { userId }) {
  return {
    ...state,
  };
}

function renewUserToken(state, { _ }) {
  return {
    ...state,
  };
}

function sendUserToken(state, { _ }) {
  return {
    ...state,
  };
}

function resetFilter(state) {
  return {
    ...state,
    ...INITIAL_STATE,
  };
}

function setUserFilter(state, { payload }) {
  return {
    ...state,
    filters: {
      ...state.filters,
      [payload.name]: payload.value,
    },
  };
}

function userListRequest(state) {
  return { ...state, loading: true };
}

function userListSuccess(state, { list }) {
  return {
    ...state,
    loading: false,
    list,
  };
}

function userListFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function userListTotal(state, { listTotal }) {
  return {
    ...state,
    loading: false,
    listTotal,
  };
}

function userImportRequest(state) {
  return { ...state, loading: true };
}

function userImportSuccess(state, { success }) {
  return {
    ...state,
    loading: false,
    success,
  };
}

function userImportFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function userDestroyRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function userDestroySuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function userDestroyFailure(state, { error }) {
  return {
    ...state,
    error,
  };
}

function usersBulkTagChangeRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function usersBulkTagChangeSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function usersBulkTagChangeFailure(state, { error }) {
  return {
    ...state,
    error,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.USER_LIST_REQUEST]: userListRequest,
  [Types.USER_LIST_SUCCESS]: userListSuccess,
  [Types.USER_LIST_FAILURE]: userListFailure,
  [Types.TOGGLE_USER]: toggleUser,
  [Types.RESET_FILTER]: resetFilter,
  [Types.RENEW_USER_TOKEN]: renewUserToken,
  [Types.SEND_USER_TOKEN]: sendUserToken,
  [Types.SET_USER_FILTER]: setUserFilter,
  [Types.USER_LIST_TOTAL]: userListTotal,
  [Types.USER_IMPORT_REQUEST]: userImportRequest,
  [Types.USER_IMPORT_SUCCESS]: userImportSuccess,
  [Types.USER_IMPORT_FAILURE]: userImportFailure,
  [Types.USER_DESTROY_REQUEST]: userDestroyRequest,
  [Types.USER_DESTROY_SUCCESS]: userDestroySuccess,
  [Types.USER_DESTROY_FAILURE]: userDestroyFailure,
  [Types.USERS_BULK_TAG_CHANGE_REQUEST]: usersBulkTagChangeRequest,
  [Types.USERS_BULK_TAG_CHANGE_SUCCESS]: usersBulkTagChangeSuccess,
  [Types.USERS_BULK_TAG_CHANGE_FAILURE]: usersBulkTagChangeFailure,
});
