import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import { Creators as AuthActions, Types as AuthTypes } from './ducks';
import { Creators as AdminUserActions } from '../adminUser/ducks';

export function* authSignIn({ payload }) {
  try {
    const { data } = yield call(api.post, '/auth/admin', payload);
    const { user, token } = data;

    yield put(AdminUserActions.setData({ token, signed: true, profile: user }));

    yield put(AuthActions.signInSuccess());

    api.defaults.headers.Authorization = `Bearer ${token}`;

    history.push('/');
    history.go();
  } catch {
    toast.error('Não foi possível fazer login');
    yield put(AuthActions.signInFailure('Não foi possível fazer login.'));
  }
}

export function authSignout() {
  history.push('/login');
  history.go();
}

export default function* rootSaga() {
  return yield all([
    takeLatest(AuthTypes.SIGN_IN_REQUEST, authSignIn),
    takeLatest(AuthTypes.SIGN_OUT, authSignout),
  ]);
}
