export default {
  fontFamily: 'Poppins',
  h1: {
    fontSize: 25,
    fontWeight: 600,
  },
  h2: {
    fontSize: 20,
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 600,
  },
  caption: {
    fontSize: 14,
    fontWeight: 400,
  },
  button: {
    fontWeight: 600,
    textTransform: 'none',
  },
};
