import { combineReducers } from 'redux';

import adminUser from './adminUser/ducks';
import auth from './auth/ducks';
import modal from './modal/ducks';
import user from './user/ducks';
import users from './users/ducks';
import tag from './tag/ducks';
import component from './component/ducks';
import conference from './conference/ducks';
import ranking from './ranking/ducks';
import room from './rooms/ducks';
import points from './points/ducks';
import questionItem from './questionItem/ducks';
import resourceItem from './resourceItem/ducks';
import launchPlanItem from './launchPlanItem/ducks';

export default combineReducers({
  adminUser,
  auth,
  component,
  modal,
  user,
  users,
  tag,
  conference,
  ranking,
  room,
  points,
  questionItem,
  resourceItem,
  launchPlanItem,
});
