import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Cancel as DeleteIcon } from '@material-ui/icons';

import { Creators as ComponentActions } from '~/store/modules/component/ducks';

import ComponentContext from '../../index.context';

export default function WarmupRooms() {
  const dispatch = useDispatch();
  const currentComponent = useSelector((state) => state.component.current);
  const { list: roomList } = currentComponent.warmup_room;
  const { saveComponent, isUpdating, updateComponent } = useContext(
    ComponentContext
  );
  const defaultWarmupRoom = {
    cuid: '',
    name: '',
    description: '',
    speaker: '',
    link: '',
  };
  const [warmupRoom, setWarmupRoom] = useState({ ...defaultWarmupRoom });

  const setData = ({ name, value }) => {
    setWarmupRoom((state) => ({ ...state, [name]: value }));
  };

  const resetWarmupRoom = () => {
    setWarmupRoom({ ...defaultWarmupRoom });
  };

  const addWarmupRoom = () => {
    const newList = [...roomList];
    warmupRoom.cuid = cuid();
    newList.push({ ...warmupRoom });
    dispatch(
      ComponentActions.setComponentKindData({
        kind: 'warmup_room',
        name: 'list',
        value: newList,
      })
    );
    resetWarmupRoom();
  };

  const removeRoom = (roomId) => {
    const newList = roomList.filter((item) => item.cuid !== roomId);
    dispatch(
      ComponentActions.setComponentKindData({
        kind: 'warmup_room',
        name: 'list',
        value: newList,
      })
    );
    resetWarmupRoom();
  };

  return (
    <Container>
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Grid container spacing={1}>
          <Grid item xs={6} md={2}>
            <TextField
              name="name"
              variant="outlined"
              label="Título da Sala"
              placeholder="Ex: Aquecimento Faixa Marrom"
              InputLabelProps={{
                shrink: true,
              }}
              value={warmupRoom.name}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              name="description"
              variant="outlined"
              label="Descrição da Sala"
              placeholder="Ex: Aqui acontecerá o aquecimento para os faixas marrom sobre..."
              InputLabelProps={{
                shrink: true,
              }}
              value={warmupRoom.description}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              name="speaker"
              variant="outlined"
              label="Nome do Palestrante"
              InputLabelProps={{
                shrink: true,
              }}
              value={warmupRoom.speaker}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              name="link"
              variant="outlined"
              label="Link da Sala"
              placeholder="Ex: https://zoom.com/123456"
              InputLabelProps={{
                shrink: true,
              }}
              value={warmupRoom.link}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={addWarmupRoom}
              style={{ height: '55px' }}
              fullWidth
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box
        width={1}
        my={1}
        height={500}
        p={2}
        style={{ overflowY: 'auto', background: '#e8e8e8' }}
      >
        {!roomList.length && (
          <Box width={1} textAlign="center">
            <Typography variant="h6">
              Nenhuma sala criada até o momento
            </Typography>
          </Box>
        )}

        {!!roomList.length && (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <Grid container spacing={1}>
              {roomList.map((warmupRoomItem) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={warmupRoomItem.cuid}
                >
                  <Paper variant="outlined">
                    <Box
                      width={1}
                      height={300}
                      p={2}
                      textOverflow="ellipsis"
                      position="relative"
                    >
                      <Box
                        width={1}
                        position="absolute"
                        top={0}
                        right={0}
                        textAlign="right"
                      >
                        <IconButton
                          onClick={() => removeRoom(warmupRoomItem.cuid)}
                          aria-label="delete"
                          color="secondary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Box width={1} mb={1}>
                        <Typography variant="h6">
                          {warmupRoomItem.name}
                        </Typography>
                      </Box>
                      <Box width={1} mb={2}>
                        <Typography variant="subtitle1">
                          {warmupRoomItem.speaker}
                        </Typography>
                      </Box>
                      <Box width={1} height={80} mb={3}>
                        <Typography variant="body1">
                          {warmupRoomItem.description}
                        </Typography>
                      </Box>
                      <Box width={1}>
                        <Button
                          component="a"
                          target="_blank"
                          href={warmupRoomItem.link}
                          rel="noopener"
                          variant="contained"
                          color="primary"
                        >
                          Entrar na Sala
                        </Button>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>

      <Box mb={3} display="flex" justifyContent="flex-end">
        {isUpdating && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={updateComponent}
          >
            Atualizar
          </Button>
        )}
        {!isUpdating && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={saveComponent}
          >
            Salvar
          </Button>
        )}
      </Box>
    </Container>
  );
}
