import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core';
import Proptypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { Delete, Edit } from '@material-ui/icons';
import RevokeScore from '../revokeScore';

export default function AffiliationTable({ scoreKindDetails, closeCallback }) {
  const formatDate = (date) => format(parseISO(date), 'dd/MM/yyyy');
  const [showRevokeActions, setRevokeActions] = useState(false);
  const [revokeOptions, setRevokeOptions] = useState();

  const handleActions = (action, id) => {
    setRevokeOptions({ action, id });
    setRevokeActions(true);
  };

  const actionsCallback = () => {
    setRevokeActions(false);
  };

  return (
    <>
      {showRevokeActions ? (
        <RevokeScore
          options={revokeOptions}
          callback={actionsCallback}
          closeCallback={closeCallback}
          kind="affiliation"
        />
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Data de Pontuação</TableCell>
                <TableCell align="right">Pontuação</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!scoreKindDetails &&
                !!scoreKindDetails.length &&
                scoreKindDetails.map((scoreDetail) => (
                  <TableRow key={scoreDetail.id}>
                    <TableCell component="th" scope="row">
                      {scoreDetail.affiliate_user.name}
                    </TableCell>
                    <TableCell align="center">
                      {scoreDetail.affiliate_user.email}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(scoreDetail.created_at)}
                    </TableCell>
                    <TableCell align="right">
                      {scoreDetail.point_value}
                    </TableCell>
                    <TableCell align="right" style={{ display: 'flex' }}>
                      <IconButton
                        onClick={() => handleActions('remove', scoreDetail.id)}
                        title="Remover pontuação"
                      >
                        <Delete />
                      </IconButton>
                      <IconButton
                        onClick={() => handleActions('edit', scoreDetail.id)}
                        title="Editar pontuação"
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

AffiliationTable.propTypes = {
  scoreKindDetails: Proptypes.array,
};

AffiliationTable.defaultProps = {
  scoreKindDetails: [],
};
