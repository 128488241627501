import styled from 'styled-components';

export const Container = styled.div`
  background: rgb(0,55,94);
  background: radial-gradient(circle, rgba(0,55,94,1) 13%, rgba(0,7,14,1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #504d4d;
`;
