import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { Creators as ComponentActions } from '~/store/modules/component/ducks';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ComponentContext from '../../index.context';
import conferenceButtonSchema from './validation/conferenceButton.schema';

export default function ConferenceButton() {
  const dispatch = useDispatch();
  const currentComponent = useSelector((state) => state.component.current);
  const { saveComponent, isUpdating, updateComponent } = useContext(
    ComponentContext
  );

  const setData = ({ name, value }) => {
    dispatch(
      ComponentActions.setComponentKindData({
        kind: 'conference_button',
        name,
        value,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      button_fallback_link:
        currentComponent.conference_button.button_fallback_link,
      button_text: currentComponent.conference_button.button_text,
    },
    validationSchema: conferenceButtonSchema,
    onSubmit: (values) => {
      const entries = Object.entries(values);
      entries.forEach((item) => {
        const [name, value] = item;
        setData({ name, value });
      });
      if (isUpdating) {
        updateComponent();
      } else {
        saveComponent();
      }
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <Box
      className="h-100"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      pt={5}
    >
      <Container maxWidth="xs">
        <Box width={1} mb={3}>
          <TextField
            name="button_text"
            variant="outlined"
            label="Texto do Botão"
            placeholder="Entrar no Evento"
            helperText={formik.errors.button_text}
            error={formik.errors.button_text}
            value={formik.values.button_text}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
        <Box width={1} mb={3}>
          <TextField
            name="button_fallback_link"
            variant="outlined"
            label="Link de Reserva"
            placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            InputLabelProps={{
              shrink: true,
            }}
            helperText={formik.errors.button_fallback_link}
            error={formik.errors.button_fallback_link}
            value={formik.values.button_fallback_link}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
        <Box mb={3} display="flex" justifyContent="flex-start">
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            disabled={!formik.isValid}
            onClick={formik.submitForm}
          >
            {isUpdating ? 'Atualizar' : 'Salvar'}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
