import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Creators as PointsActions, Types as PointsTypes } from './ducks';
import { getRanking } from '../ranking/sagas';

export function* revokeUserPoint({ request }) {
  try {
    yield call(api.delete, `/points/${request.kind}/${request.id}`);
    yield put(PointsActions.revokePointsSuccess('criado com sucesso'));
    toast.success('Pontuação removida com sucesso');
    yield getRanking();

    request.close();
  } catch ({ response }) {
    let error =
      'Não foi possível remover a pontuação, tente novamente mais tarde';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);

    yield put(PointsActions.revokePointsFailure(error));
  }
}

export function* updateUserPoint({ request }) {
  try {
    yield call(api.patch, `/points/${request.id}`, request);
    yield put(PointsActions.revokePointsSuccess('criado com sucesso'));
    toast.success('Pontuação alterada com sucesso');
    yield getRanking();

    request.close();
  } catch ({ response }) {
    let error =
      'Não foi possível alterar a pontuação, tente novamente mais tarde';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);

    yield put(PointsActions.revokePointsFailure(error));
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(PointsTypes.REVOKE_POINTS_REQUEST, revokeUserPoint),
    takeLatest(PointsTypes.UPDATE_POINTS_REQUEST, updateUserPoint),
  ]);
}
