import { call, put, all, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Creators as ComponentActions, Types as ComponentTypes } from './ducks';

const getCurrentComponent = (state) => state.component.current;

export function* fetchAllComponents() {
  try {
    const { data } = yield call(api.get, `components`);
    yield put(ComponentActions.fetchAllComponentsSuccess(data.components));
  } catch ({ response }) {
    yield put(
      ComponentActions.fetchAllComponentsFailure({
        error: 'Falha ao buscar lista de componentes',
      })
    );
  }
}

export function* createComponent({ payload }) {
  const currentComponent = yield select(getCurrentComponent);

  try {
    yield call(api.post, '/components', currentComponent);
    yield put(ComponentActions.componentCreateSuccess('criado com sucesso'));
    yield fetchAllComponents();
    payload.close();

    toast.success('Componente criado com sucesso');
  } catch (response) {
    yield put(
      ComponentActions.componentCreateFailure(
        'Não foi possível criar o Componente'
      )
    );

    toast.error('Erro inesperado ao criar o Componente');
  }
}

export function* updateComponent({ payload }) {
  const currentComponent = yield select(getCurrentComponent);

  try {
    yield call(
      api.patch,
      `/components/${currentComponent.id}`,
      currentComponent
    );
    yield put(ComponentActions.componentCreateSuccess('criado com sucesso'));
    yield fetchAllComponents();
    payload.close();

    toast.success('Componente atualizado com sucesso');
  } catch (response) {
    yield put(
      ComponentActions.componentCreateFailure(
        'Não foi possível atualizar o Componente'
      )
    );

    toast.error('Erro inesperado ao atualizar o Componente');
  }
}

export function* deleteComponent({ payload }) {
  const currentComponent = yield select(getCurrentComponent);

  try {
    yield call(
      api.delete,
      `/components/${currentComponent.id}`,
      currentComponent
    );
    yield put(ComponentActions.componentDeleteSuccess('Excluído com sucesso'));
    yield fetchAllComponents();
    payload.close();
    toast.success('Componente excluído com sucesso');
  } catch (response) {
    yield put(
      ComponentActions.componentDeleteFailure(
        'Não foi possível excluir o Componente'
      )
    );

    toast.error('Erro inesperado ao excluir o Componente');
  }
}

export function* toggleComponent({ componentId }) {
  try {
    yield call(api.patch, `/components/${componentId}/toggle`);
    yield fetchAllComponents();
    toast.success('Componente atualizado com sucesso');
  } catch (response) {
    toast.error('Erro inesperado ao atualizar o componente');
  }
}

export function* fetchComponentKindData() {
  const currentComponent = yield select(getCurrentComponent);

  try {
    if (currentComponent.saleLetterId) {
      const route = `/saleLetters/${currentComponent.saleLetterId}`;
      const { data } = yield call(api.get, route);
      yield put(
        ComponentActions.setComponentData({
          name: 'kind',
          value: 'sale_letter',
        })
      );
      yield put(
        ComponentActions.setComponentData({
          name: 'sale_letter',
          value: data.saleLetter,
        })
      );
    }

    if (currentComponent.countdownId) {
      const route = `/countdowns/${currentComponent.countdownId}`;
      const { data } = yield call(api.get, route);
      yield put(
        ComponentActions.setComponentData({ name: 'kind', value: 'countdown' })
      );
      yield put(
        ComponentActions.setComponentData({
          name: 'countdown',
          value: data.countdown,
        })
      );
    }

    if (currentComponent.conferenceButtonId) {
      const route = `/conferenceButtons/${currentComponent.conferenceButtonId}`;
      const { data } = yield call(api.get, route);
      yield put(
        ComponentActions.setComponentData({
          name: 'kind',
          value: 'conference_button',
        })
      );
      yield put(
        ComponentActions.setComponentData({
          name: 'conference_button',
          value: data.conferenceButton,
        })
      );
    }

    if (currentComponent.resourceId) {
      const route = `/resources/${currentComponent.resourceId}`;
      const { data } = yield call(api.get, route);
      yield put(
        ComponentActions.setComponentData({ name: 'kind', value: 'resource' })
      );
      yield put(
        ComponentActions.setComponentData({
          name: 'resource',
          value: data.resource,
        })
      );
    }

    if (currentComponent.warmupRoomId) {
      const route = `/warmupRooms/${currentComponent.warmupRoomId}`;
      const { data } = yield call(api.get, route);
      yield put(
        ComponentActions.setComponentData({
          name: 'kind',
          value: 'warmup_room',
        })
      );
      yield put(
        ComponentActions.setComponentData({
          name: 'warmup_room',
          value: data.warmupRoom,
        })
      );
    }
  } catch (response) {
    yield put(
      ComponentActions.componentCreateFailure(
        'Erro carregando dados do tipo de componente'
      )
    );

    toast.error('Erro carregando dados do tipo de componente');
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(ComponentTypes.COMPONENT_CREATE_REQUEST, createComponent),
    takeLatest(ComponentTypes.COMPONENT_UPDATE_REQUEST, updateComponent),
    takeLatest(ComponentTypes.COMPONENT_DELETE_REQUEST, deleteComponent),
    takeLatest(
      ComponentTypes.FETCH_COMPONENT_KIND_DATA,
      fetchComponentKindData
    ),
    takeLatest(ComponentTypes.FETCH_ALL_COMPONENTS_REQUEST, fetchAllComponents),
    takeLatest(ComponentTypes.TOGGLE_COMPONENT, toggleComponent),
  ]);
}
