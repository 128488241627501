import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import ComponentContext from '../../index.context';

function AffiliateComponent() {
  const { saveComponent, isUpdating, updateComponent } = useContext(
    ComponentContext
  );
  const { error: componentError, loading, componentSuccess } = useSelector(
    (state) => state.component
  );
  const [operationKind, setOperationKind] = useState('salvar');

  useEffect(() => {
    if (isUpdating) {
      setOperationKind('atualizar');
      return updateComponent();
    }
    setOperationKind('salvar');
    return saveComponent();
  }, []);

  return (
    <Box width={1}>
      {loading && !componentError && !componentSuccess && (
        <Typography variant="h6">
          ...Aguarde enquanto executamos a operação
        </Typography>
      )}

      {!loading && !!componentSuccess && (
        <Typography variant="h6">
          Sucesso ao {operationKind} o componente
        </Typography>
      )}

      {!loading && !!componentError && (
        <Typography variant="h6">
          Erro ao {operationKind} o componente, feche e tente novamente
        </Typography>
      )}
    </Box>
  );
}

export default AffiliateComponent;
