import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core';
import Proptypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { CloudDownload, Delete, Edit } from '@material-ui/icons';
import RevokeScore from '../revokeScore';

export default function QuestionTable({ scoreKindDetails, closeCallback }) {
  const formatDate = (date) => format(parseISO(date), 'dd/MM/yyyy');
  const [showRevokeActions, setRevokeActions] = useState(false);
  const [revokeOptions, setRevokeOptions] = useState();
  let participantAnswer: '';

  const formatAnswerKind = (option) => {
    const options = {
      Evidence: 'Evidência',
      MultiAnswer: 'Multi-Resposta',
      MultiChoice: 'Multi-Escolhas',
      SingleChoice: 'Simples',
    };
    return options[option];
  };

  const extractSimpleAnswer = (questions) => {
    const questionsItems = questions.questionItem.details.choices;
    return questionsItems.map((question) => {
      if (question.cuid === questions.answer.item) {
        participantAnswer = question.label;
      }
    });
  };

  const extractEvidenceAnswer = (questions) => {
    participantAnswer = (
      <IconButton
        onClick={() =>
          window.open(questions.answer.choice, '_blank', 'noopener,noreferrer')
        }
      >
        <CloudDownload />
      </IconButton>
    );
  };

  const extractMultiAnswer = (questions) => {
    const result = questions.answer.items.map((item) => {
      return item.answer;
    });
    participantAnswer = result.join(', ');
  };

  const extractMultiChoice = (questions) => {
    participantAnswer = '';
    const tempParticipantAnswer = [];

    const participantChoices = questions.answer.items;
    const questionsItems = questions.questionItem.details.choices;
    questionsItems.map((element) => {
      if (participantChoices.includes(element.cuid)) {
        tempParticipantAnswer.push(element.label);
      }
    });
    participantAnswer = tempParticipantAnswer.join(', ');
  };

  const getQuestionAnwer = (question) => {
    const getCorrectAnswer = {
      Evidence: () => extractEvidenceAnswer(question),
      MultiAnswer: () => extractMultiAnswer(question),
      MultiChoice: () => extractMultiChoice(question),
      SingleChoice: () => extractSimpleAnswer(question),
    };
    return getCorrectAnswer[question.questionItem.kind]();
  };

  const handleActions = (action, id) => {
    setRevokeOptions({ action, id });
    setRevokeActions(true);
  };

  const actionsCallback = () => {
    setRevokeActions(false);
  };

  return (
    <>
      {showRevokeActions ? (
        <RevokeScore
          options={revokeOptions}
          callback={actionsCallback}
          closeCallback={closeCallback}
          kind="question"
        />
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Título da Pergunta</TableCell>
                <TableCell align="center">Tipo de Pergunta</TableCell>
                <TableCell align="center">Resposta</TableCell>
                <TableCell align="center">Data de Pontuação</TableCell>
                <TableCell align="right">Pontuação</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!scoreKindDetails.length &&
                scoreKindDetails.map((scoreDetail) => {
                  getQuestionAnwer(scoreDetail.answer);
                  return (
                    <TableRow key={scoreDetail.id}>
                      <TableCell component="th" scope="row">
                        {scoreDetail.answer.questionItem.title}
                      </TableCell>
                      <TableCell align="center">
                        {formatAnswerKind(scoreDetail.answer.questionItem.kind)}
                      </TableCell>
                      <TableCell align="center">{participantAnswer}</TableCell>
                      <TableCell align="center">
                        {formatDate(scoreDetail.created_at)}
                      </TableCell>
                      <TableCell align="center">
                        {scoreDetail.point_value}
                      </TableCell>
                      <TableCell align="right" style={{ display: 'flex' }}>
                        <IconButton
                          onClick={() =>
                            handleActions('remove', scoreDetail.id)
                          }
                          title="Remover pontuação"
                        >
                          <Delete />
                        </IconButton>
                        <IconButton
                          onClick={() => handleActions('edit', scoreDetail.id)}
                          title="Editar pontuação"
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

QuestionTable.propTypes = {
  scoreKindDetails: Proptypes.array,
};

QuestionTable.defaultProps = {
  scoreKindDetails: [],
};
