import React, { useEffect, useState } from 'react';

import {
  Container,
  Box,
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { ExpandMore, Info } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import ScoreKindDetails from '~/pages/Ranking/components/scoreKindDetails';

import {
  AccordionColumns,
  IconTableCell,
  AccordionColumnsHeader,
  StyledAccordionSummary,
  StyledList,
  StyledListItem,
  StyledAccordion,
} from './styles';

import { Creators as RankingActions } from '~/store/modules/ranking/ducks';
import { Creators as ModalActions } from '~/store/modules/modal/ducks';

export default function Ranking() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const activeModal = useSelector((state) => state.modal.active);
  const {
    top100: { result: participants },
    scoreDetail: { result: participantDetails },
  } = useSelector((state) => state.ranking);
  const { pointEnabled } = useSelector((state) => state.conference.config);
  const handleScoreClick = (participantId) => (event, isExpanded) => {
    if (isExpanded) {
      dispatch(RankingActions.top100ScoresRequest(participantId));
    }
    setExpanded(isExpanded ? participantId : false);
  };

  const renderRedirect = () => {
    if (pointEnabled !== undefined && pointEnabled === false) {
      return <Redirect to="/" />;
    }
    return <></>;
  };

  useEffect(() => {
    dispatch(RankingActions.top100Request());
  }, [dispatch]);

  const formatKindOptions = (option) => {
    const options = {
      question: 'Questionário',
      component: 'Componentes',
      affiliation: 'Afiliação',
      resource: 'Recurso de Aula',
      launchPlan: 'Plano de Lançamento',
    };
    return options[option];
  };

  const handleDetailsClick = (scoreDetail) => {
    const options = {
      kind: scoreDetail.kind,
      user_id: scoreDetail.user_id,
    };
    dispatch(RankingActions.top100ScoresDetailsRequest(options));
    return dispatch(
      ModalActions.openModal({ name: 'SCORE_KIND_DETAILS', params: options })
    );
  };

  const handleCloseModal = () => {
    dispatch(ModalActions.closeModal());
    dispatch(RankingActions.top100ScoresDetailsReset());
  };

  return (
    <>
      {renderRedirect()}
      <Container>
        {activeModal === 'SCORE_KIND_DETAILS' && (
          <ScoreKindDetails closeCallback={handleCloseModal} />
        )}
        <Box
          display="flex"
          width={1}
          flexDirection="column"
          justifyContent="flex-start"
        >
          <Box pt={4}>
            <Accordion>
              <StyledAccordionSummary disabled>
                <AccordionColumnsHeader>Ranking</AccordionColumnsHeader>
                <AccordionColumnsHeader>
                  Nome do Participante
                </AccordionColumnsHeader>
                <AccordionColumnsHeader align="right">
                  Tag
                </AccordionColumnsHeader>
                <AccordionColumnsHeader>Pontuação Total</AccordionColumnsHeader>
                <AccordionColumnsHeader>Detalhes</AccordionColumnsHeader>
              </StyledAccordionSummary>
            </Accordion>
            <StyledList>
              {!!participants &&
                participants.map((participant, index) => (
                  <StyledListItem key={participant.user_id}>
                    <StyledAccordion
                      expanded={expanded === participant.user_id}
                      onChange={handleScoreClick(participant.user_id)}
                      TransitionProps={{ unmountOnExit: true }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <AccordionColumns>
                          <Typography>{index + 1}° Lugar</Typography>
                        </AccordionColumns>
                        <AccordionColumns>
                          <Typography>{participant.username}</Typography>
                        </AccordionColumns>
                        <AccordionColumns>
                          <Typography>{participant.tag_name}</Typography>
                        </AccordionColumns>
                        <AccordionColumns>
                          <Typography>{participant.total_points}</Typography>
                        </AccordionColumns>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Tipo de Pontuação</TableCell>
                                <TableCell align="right">
                                  Total de Pontos
                                </TableCell>
                                <TableCell align="right">Detalhes</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {!!participantDetails &&
                                participantDetails.map((scoreDetail) => (
                                  <TableRow key={scoreDetail.kind}>
                                    <TableCell component="th" scope="row">
                                      {formatKindOptions(scoreDetail.kind)}
                                    </TableCell>
                                    <TableCell align="right">
                                      {scoreDetail.total_points}
                                    </TableCell>
                                    <IconTableCell
                                      onClick={() =>
                                        handleDetailsClick(scoreDetail)
                                      }
                                      align="right"
                                    >
                                      <Info />
                                    </IconTableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </StyledAccordion>
                  </StyledListItem>
                ))}
            </StyledList>
          </Box>
        </Box>
      </Container>
    </>
  );
}
