import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Creators as PointsActions } from '~/store/modules/points/ducks';

export default function RevokeScore({
  options,
  callback,
  closeCallback,
  kind,
}) {
  const dispatch = useDispatch();
  const [newScore, setNewScore] = useState('');

  const handleChange = (value) => {
    return setNewScore(value.toString().replace(/\D/g, ''));
  };

  const handleConfirmButton = (action) => {
    const formValues = {
      id: options.id,
      point: action === 'update' ? newScore : '',
      close: closeCallback,
      kind,
    };
    if (action === 'update') {
      return dispatch(PointsActions.updatePointsRequest(formValues));
    }
    return dispatch(PointsActions.revokePointsRequest(formValues));
  };

  return (
    <>
      {options && options.action === 'edit' ? (
        <Container maxWidth="xs">
          <Box mb={4}>
            <TextField
              name="score"
              variant="outlined"
              label="Nova pontuação"
              value={newScore}
              onChange={({ target }) => handleChange(target.value)}
              fullWidth
            />
          </Box>
          <Box mb={4} style={{ textAlign: 'center' }}>
            <Button
              style={{ marginRight: '30px' }}
              variant="contained"
              size="large"
              color="primary"
              onClick={() => callback()}
            >
              Voltar
            </Button>
            <Button
              disabled={!newScore}
              variant="contained"
              size="large"
              color="primary"
              onClick={() => handleConfirmButton('update')}
            >
              Atualizar
            </Button>
          </Box>
        </Container>
      ) : (
        <Container maxWidth="xs">
          <Box mb={4}>
            <Typography variant="h6">
              Você deseja realmente remover esse ponto do participante?
            </Typography>
          </Box>
          <Box style={{ textAlign: 'center' }}>
            <Button
              style={{ marginRight: '30px' }}
              variant="contained"
              size="large"
              color="primary"
              onClick={() => callback()}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => handleConfirmButton('remove')}
            >
              Remover
            </Button>
          </Box>
        </Container>
      )}
    </>
  );
}

RevokeScore.propTypes = {
  options: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
};
