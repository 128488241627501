import React, { useState } from 'react';
import { Box, Slide } from '@material-ui/core';
import LaunchPlanStep from './steps/launchPlan';
import ItemsStep from './steps/items';
import LaunchPlanContext from './index.context';

function LaunchPlanMain() {
  const [currentStep, setCurrentStep] = useState('launch_plan');

  const setStep = (step) => {
    setCurrentStep(step);
  };

  return (
    <LaunchPlanContext.Provider
      value={{
        currentStep,
        setStep,
      }}
    >
      {currentStep === 'launch_plan' && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <Box width={1}>
            <LaunchPlanStep />
          </Box>
        </Slide>
      )}
      {currentStep === 'items' && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <Box width={1}>
            <ItemsStep />
          </Box>
        </Slide>
      )}
    </LaunchPlanContext.Provider>
  );
}

export default LaunchPlanMain;
