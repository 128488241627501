import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import SaleLetterStep from './saleLetter';
import CountdownStep from './countdown';
import ResourceStep from './resource';
import QuestionStep from './questions';
import AffiliateStep from './affiliate';
import ConferenceButton from './conferenceButton';
import LaunchPlan from './launchPlan';
import WarmupRoomStep from './warmupRoom';

export default function ComponentWelcomeStep() {
  const currentComponent = useSelector((state) => state.component.current);

  return (
    <Box
      className="h-100"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      pt={5}
    >
      {currentComponent.kind === 'affiliate' && <AffiliateStep />}

      {currentComponent.kind === 'sale_letter' && <SaleLetterStep />}

      {currentComponent.kind === 'countdown' && <CountdownStep />}

      {currentComponent.kind === 'conference_button' && <ConferenceButton />}

      {currentComponent.kind === 'resource' && <ResourceStep />}

      {currentComponent.kind === 'question' && <QuestionStep />}

      {currentComponent.kind === 'launch_plan' && <LaunchPlan />}

      {currentComponent.kind === 'warmup_room' && <WarmupRoomStep />}
    </Box>
  );
}
