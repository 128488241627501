import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cuid from 'cuid';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Cancel as DeleteIcon } from '@material-ui/icons';

import { Creators as ComponentActions } from '~/store/modules/component/ducks';
import { Creators as ResourceItemsActions } from '~/store/modules/resourceItem/ducks';
import api from '~/services/api';

import ComponentContext from '../../../../index.context';

const RoundedPaper = styled(Paper)`
  border-radius: 5px !important;
`;

export default function ResourceComponent() {
  const dispatch = useDispatch();
  const currentComponent = useSelector((state) => state.component.current);
  const {
    items: resourceItems,
    id: resourceComponentId,
  } = currentComponent.resource;
  const { saveComponent, isUpdating, updateComponent } = useContext(
    ComponentContext
  );
  const initialResources = {
    cuid: '',
    title: '',
    description: '',
    link: '',
    point_value: '0',
  };
  const [resourceItem, setResources] = useState({ ...initialResources });
  const { pointEnabled } = useSelector((state) => state.conference.config);
  const setData = ({ name, value }) => {
    setResources((state) => ({ ...state, [name]: value }));
  };

  const resetResources = () => {
    setResources({ ...initialResources });
  };

  const addresource = async () => {
    const newList = [...resourceItems];

    if (isUpdating) {
      dispatch(
        ResourceItemsActions.createResourceItemRequest({
          resourceItem: {
            ...resourceItem,
            resourceId: resourceComponentId,
          },
          newList,
        })
      );
    } else {
      resourceItem.cuid = cuid();
      newList.push({ ...resourceItem });

      dispatch(
        ComponentActions.setComponentKindData({
          kind: 'resource',
          name: 'items',
          value: newList,
        })
      );
    }

    resetResources();
  };

  const removeResource = (resourceId) => {
    let newList = [];

    if (isUpdating) {
      newList = resourceItems.filter((item) => item.id !== resourceId);
      dispatch(
        ResourceItemsActions.destroyResourceItemRequest({ id: resourceId })
      );
    } else {
      newList = resourceItems.filter((item) => item.cuid !== resourceId);
    }

    dispatch(
      ComponentActions.setComponentKindData({
        kind: 'resource',
        name: 'items',
        value: newList,
      })
    );
    resetResources();
  };

  return (
    <Container>
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Grid container spacing={1}>
          <Grid item xs={6} md={4}>
            <TextField
              name="title"
              variant="outlined"
              label="Nome do recurso"
              placeholder="Ex: GPS Faixa Branca"
              InputLabelProps={{
                shrink: true,
              }}
              value={resourceItem.title}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={5}>
            <TextField
              name="link"
              variant="outlined"
              label="Link do Arquivo"
              placeholder="Ex: https://drive.google.com/123456"
              InputLabelProps={{
                shrink: true,
              }}
              value={resourceItem.link}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              disabled={!pointEnabled || !currentComponent.point_on_click}
              name="point_value"
              variant="outlined"
              label="Número de pontos"
              placeholder="Ex: 10"
              InputLabelProps={{
                shrink: true,
              }}
              value={resourceItem.point_value}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              name="description"
              variant="outlined"
              label="Descrição"
              multiline
              rows={4}
              InputLabelProps={{
                shrink: true,
              }}
              value={resourceItem.description}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={3}>
            <Box mt={3}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={addresource}
                style={{ height: '55px' }}
                fullWidth
              >
                Adicionar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        width={1}
        my={1}
        height={500}
        p={2}
        style={{ overflowY: 'auto', background: '#e8e8e8' }}
      >
        {!resourceItems.length && (
          <Box width={1} textAlign="center">
            <Typography variant="h6">
              Nenhuma recurso criado até o momento
            </Typography>
          </Box>
        )}

        {!!resourceItems.length && (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <Grid container spacing={1}>
              {resourceItems.map((item) => (
                <Grid item xs={12} sm={6}>
                  <RoundedPaper variant="outlined">
                    <Box
                      display="flex"
                      alignItems="center"
                      width={1}
                      height={100}
                      p={2}
                      textOverflow="ellipsis"
                      position="relative"
                    >
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={9}>
                          <Typography variant="h6">{item.title}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            component="a"
                            target="_blank"
                            href={item.link}
                            rel="noopener"
                            variant="contained"
                            color="primary"
                            disableElevation
                          >
                            Baixar
                          </Button>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() =>
                              removeResource(isUpdating ? item.id : item.cuid)
                            }
                            aria-label="delete"
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </RoundedPaper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>

      <Box mb={3} display="flex" justifyContent="flex-end">
        {isUpdating && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={updateComponent}
          >
            Atualizar Recursos
          </Button>
        )}
        {!isUpdating && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={saveComponent}
          >
            Salvar Recursos
          </Button>
        )}
      </Box>
    </Container>
  );
}
