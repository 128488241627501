import styled from 'styled-components';
import { AppBar as Bar, IconButton, Link } from '@material-ui/core';

export const ContainerImage = styled.figure`
  width: 200px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }
`;

export const CustomLink = styled(Link)`
  color: #2e3133 !important;
  margin-right: 20px !important;

  &:hover {
    cursor: pointer;
  }
`;

export const AppBar = styled(Bar)`
  background-color: #fff !important;
  border-top: ${props => (props.borderTop ? '1px solid #E6EAF2' : 'none')};
  box-shadow: ${props =>
    props.shadow
      ? '-1px 11px 15px 2px rgba(0, 0, 0, 0.02)'
      : 'none'} !important;
`;

export const AvatarButton = styled(IconButton)`
  border-radius: 45px !important;
`;

export const AvatarName = styled.span`
  color: #000;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
`;
