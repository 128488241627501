export default {
  primary: {
    main: '#1C7CED',
  },
  secondary: {
    main: '#F50057',
  },
  text: {
    primary: '#2E3133',
    secondary: '#3B3D40',
    disabled: '#737B87',
  },
};
