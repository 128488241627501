import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cuid from 'cuid';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Cancel as DeleteIcon } from '@material-ui/icons';

import { Creators as ComponentActions } from '~/store/modules/component/ducks';

import ComponentContext from '~/pages/Components/ComponentWizard/index.context';

import LaunchPlanItemKind from './itemKinds';
import { Creators as LaunchPlanItemActions } from '~/store/modules/launchPlanItem/ducks';

const RoundedPaper = styled(Paper)`
  border-radius: 5px !important;
`;

export default function LaunchPlanComponent() {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(EditorState.createEmpty());
  const currentComponent = useSelector((state) => state.component.current);
  const { launch_plan: launchPlan } = currentComponent;
  const { items: itemsList } = launchPlan;
  const { saveComponent, isUpdating, updateComponent } = useContext(
    ComponentContext
  );
  const { pointEnabled } = useSelector((state) => state.conference.config);

  const defaultLaunchPlanItem = {
    cuid: '',
    kind: '',
    title: '',
    point_value: 0,
    details: {},
  };

  const launchPlanItemKinds = [
    { label: 'Múltiplas Respostas', kind: 'MultiAnswer' },
    { label: 'Múltipla Escolha', kind: 'MultiChoice' },
    { label: 'Escolha Única', kind: 'SingleChoice' },
  ];

  const [launchPlanItem, setLaunchPlanItem] = useState({
    ...defaultLaunchPlanItem,
  });

  const setItemData = ({ name, value }) => {
    setLaunchPlanItem((state) => ({ ...state, [name]: value }));
  };

  const setChoicesData = (choices) => {
    setItemData({ name: 'details', value: { choices } });
  };

  const addLaunchItem = async () => {
    const newList = [...itemsList];

    if (isUpdating) {
      const data = {
        ...launchPlanItem,
        launchPlanId: launchPlan.id,
      };

      dispatch(
        LaunchPlanItemActions.launchPlanItemAddRequest({
          data,
          newList,
        })
      );
    } else {
      launchPlanItem.cuid = cuid();
      newList.push({ ...launchPlanItem });
    }

    dispatch(
      ComponentActions.setComponentKindData({
        kind: 'launch_plan',
        name: 'items',
        value: newList,
      })
    );

    setTitle(EditorState.createEmpty());
    return setLaunchPlanItem({ ...defaultLaunchPlanItem });
  };

  const removeLaunchItem = (launchItemId) => {
    let newList = [];

    if (isUpdating) {
      newList = itemsList.filter((item) => item.id !== launchItemId);
      dispatch(
        LaunchPlanItemActions.launchPlanItemDestroyRequest({ id: launchItemId })
      );
    } else {
      newList = itemsList.filter((item) => item.cuid !== launchItemId);
    }

    dispatch(
      ComponentActions.setComponentKindData({
        kind: 'launch_plan',
        name: 'items',
        value: newList,
      })
    );
  };

  const handleEditorChange = (content) => {
    setTitle(content);
    setItemData({
      name: 'title',
      value: draftToHtml(convertToRaw(title.getCurrentContent())),
    });
  };

  const getEditorTextFormated = (content) => {
    const limitedContent = `${content.substr(0, 256)} ...`;
    const conferenceKeyValue = htmlToDraft(limitedContent);
    const editorState = ContentState.createFromBlockArray(
      conferenceKeyValue.contentBlocks
    );

    return EditorState.createWithContent(editorState);
  };

  return (
    <Container>
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Grid container component={Box} spacing={1} pt={4} pb={2}>
          <Grid item xs={12}>
            <Box mb={1}>
              <Typography variant="body2">Pergunta:</Typography>
              <Editor
                wrapperClassName="text-editor-wrapper"
                editorClassName="text-editor-container"
                editorState={title}
                onEditorStateChange={(content) => {
                  handleEditorChange(content);
                }}
                toolbar={{
                  options: [
                    'inline',
                    'textAlign',
                    'link',
                    'history',
                    'blockType',
                    'fontSize',
                  ],
                }}
                editorStyle={{ maxHeight: '300px' }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="kind"
              variant="outlined"
              label="Tipo de Pergunta"
              placeholder="Selecione o tipo da pergunta"
              select
              InputLabelProps={{
                shrink: true,
              }}
              value={launchPlanItem.kind}
              onChange={({ target }) => setItemData(target)}
              fullWidth
            >
              {launchPlanItemKinds.map((lauchPlanItemKind) => (
                <option
                  key={lauchPlanItemKind.kind}
                  value={lauchPlanItemKind.kind}
                >
                  {lauchPlanItemKind.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={!pointEnabled}
              name="point_value"
              variant="outlined"
              label="Pontuação"
              placeholder="Quantos pontos"
              InputLabelProps={{
                shrink: true,
              }}
              value={launchPlanItem.point_value}
              onChange={({ target }) => setItemData(target)}
              fullWidth
            />
          </Grid>
        </Grid>

        <LaunchPlanItemKind
          kind={launchPlanItem.kind}
          setChoices={setChoicesData}
        />

        <Box display="flex" justifyContent="center" pt={1}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={addLaunchItem}
            style={{ height: '55px' }}
          >
            Adicionar Pergunta
          </Button>
        </Box>
      </Box>

      <Box
        width={1}
        my={1}
        height={500}
        p={2}
        style={{ overflowY: 'auto', background: '#e8e8e8' }}
      >
        {!itemsList.length && (
          <Box width={1} textAlign="center">
            <Typography variant="h6">
              Nenhuma recurso criado até o momento
            </Typography>
          </Box>
        )}

        {!!itemsList.length && (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <Grid container spacing={1}>
              {itemsList.map((item) => (
                <Grid item xs={12}>
                  <RoundedPaper variant="outlined">
                    <Box
                      display="flex"
                      alignItems="center"
                      width={1}
                      height={100}
                      p={2}
                      textOverflow="ellipsis"
                      position="relative"
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                          <Editor
                            editorState={getEditorTextFormated(item.title)}
                            onEditorStateChange={(content) => {
                              handleEditorChange(content);
                            }}
                            toolbarHidden
                            editorStyle={{ pointerEvents: 'none' }}
                          />
                        </Grid>
                        <Grid item xs={3} component={Box} textAlign="center">
                          <Grid container>
                            <Grid item xs={12}>
                              <Chip label={item.kind} />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="caption">
                                PONTUAÇÃO: {item.point_value}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1} component={Box} textAlign="right">
                          <IconButton
                            onClick={() =>
                              removeLaunchItem(isUpdating ? item.id : item.cuid)
                            }
                            aria-label="delete"
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </RoundedPaper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>

      <Box mb={3} display="flex" justifyContent="flex-end">
        {isUpdating && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={updateComponent}
          >
            Atualizar Perguntas
          </Button>
        )}
        {!isUpdating && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={saveComponent}
          >
            Salvar Perguntas
          </Button>
        )}
      </Box>
    </Container>
  );
}
