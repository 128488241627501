import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, Grid, TextField } from '@material-ui/core';

import { Creators as ComponentActions } from '~/store/modules/component/ducks';

import LaunchPlanContext from '../index.context';

export default function LaunchPlanComponent() {
  const dispatch = useDispatch();
  const { setStep } = useContext(LaunchPlanContext);
  const currentComponent = useSelector((state) => state.component.current);
  const { launch_plan: launchPlan } = currentComponent;

  const setData = ({ name, value }) => {
    dispatch(
      ComponentActions.setComponentKindData({ kind: 'launch_plan', name, value })
    );
  };

  return (
    <Container>
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="title"
              variant="outlined"
              label="Módulo:"
              placeholder="Ex: Módulo 1"
              InputLabelProps={{
                shrink: true,
              }}
              value={launchPlan.title}
              onChange={({ target }) => setData(target)}
              fullWidth
            />
          </Grid>
        </Grid>

        <Box display="flex" pb={2} justifyContent="flex-end">
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => setStep('items')}
            style={{ height: '55px' }}
          >
            Adicionar Perguntas
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
