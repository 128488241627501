import React from 'react';
import Proptypes from 'prop-types';
import { Box } from '@material-ui/core';

import { useSelector } from 'react-redux';
import StandardModal from '~/components/Modal';
import AffiliationTable from './affiliationTable';
import ComponentTable from './componentTable';
import QuestionTable from './questionTable';
import ResourceTable from './resourceTable';
import LaunchPlan from './launchPlanTable';

export default function ScoreKindDetails({ closeCallback }) {
  const { result: scoreKindDetails } = useSelector(
    (state) => state.ranking.scoreKindDetails
  );
  const { params: modalParams } = useSelector((state) => state.modal);

  const formatKindOptions = (option) => {
    const options = {
      question: 'Questionário',
      component: 'Componentes',
      affiliation: 'Afiliação',
      resource: 'Recurso de Aula',
      launchPlan: 'Plano de Lançamento',
    };
    return options[option];
  };

  const getContentKind = (kind) => {
    const options = {
      affiliation: () => (
        <AffiliationTable
          scoreKindDetails={scoreKindDetails}
          closeCallback={closeCallback}
        />
      ),
      component: () => (
        <ComponentTable
          scoreKindDetails={scoreKindDetails}
          closeCallback={closeCallback}
        />
      ),
      question: () => (
        <QuestionTable
          scoreKindDetails={scoreKindDetails}
          closeCallback={closeCallback}
        />
      ),
      resource: () => (
        <ResourceTable
          scoreKindDetails={scoreKindDetails}
          closeCallback={closeCallback}
        />
      ),
      launchPlan: () => (
        <LaunchPlan
          scoreKindDetails={scoreKindDetails}
          closeCallback={closeCallback}
        />
      ),
    };
    return options[kind]();
  };

  return (
    <StandardModal
      title={`Detalhes da Pontuação - ${formatKindOptions(modalParams.kind)}`}
      onClose={closeCallback}
      isOpened
      maxWidth="md"
      showDividers={false}
      slideTo="left"
      fullWidth
    >
      <Box
        width={1}
        className="h-100"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
      >
        {getContentKind(modalParams.kind)}
      </Box>
    </StandardModal>
  );
}

ScoreKindDetails.propTypes = {
  closeCallback: Proptypes.func.isRequired,
};
