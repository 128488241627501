import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  list: [],
  loading: false,
  error: '',
  success: '',
};

export const { Types, Creators } = createActions({
  tagListRequest: ['payload'],
  tagListSuccess: ['tags'],
  tagListFailure: ['error'],
  tagCreateRequest: ['formFields'],
  tagUpdateRequest: ['formFields'],
});

function tagListRequest(state) {
  return { ...state, loading: true };
}

function tagListSuccess(state, { tags }) {
  return {
    ...state,
    loading: false,
    list: tags,
  };
}

function tagListFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function tagCreateRequest(state) {
  return { ...state, loading: true };
}

function tagUpdateRequest(state) {
  return { ...state, loading: true };
}

export default createReducer(INITIAL_STATE, {
  [Types.TAG_LIST_REQUEST]: tagListRequest,
  [Types.TAG_LIST_SUCCESS]: tagListSuccess,
  [Types.TAG_LIST_FAILURE]: tagListFailure,
  [Types.TAG_CREATE_REQUEST]: tagCreateRequest,
  [Types.TAG_UPDATE_REQUEST]: tagUpdateRequest,
});
