import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Creators as UserActions, Types as UserTypes } from './ducks';

export function* createUser({ payload }) {
  try {
    yield call(api.post, '/users', payload);
    yield put(UserActions.userSuccess('criado com sucesso'));

    toast.success('Participante criado com sucesso');
    payload.close();
  } catch ({ response }) {
    let error = 'Não foi possível criar o participante';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);

    yield put(UserActions.userFailure(error));
  }
}

export function* updateUser({ payload }) {
  try {
    yield call(api.patch, `/users/${payload.id}`, payload);
    yield put(UserActions.userSuccess('Atualizado com sucesso'));

    toast.success('Participante atualizado com sucesso');
    payload.close();
  } catch ({ response }) {
    let error = 'Não foi possível atualizar o participante';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);

    yield put(UserActions.userFailure(error));
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(UserTypes.USER_CREATE_REQUEST, createUser),
    takeLatest(UserTypes.USER_UPDATE_REQUEST, updateUser),
  ]);
}
