import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  token: null,
  signed: false,
  profile: {
    name: '',
    email: '',
  },
};

export const { Types, Creators } = createActions({
  setData: ['payload'],
  resetData: ['payload'],
});

function setData(state, { payload }) {
  return {
    ...state,
    ...payload,
  };
}

function resetData(state) {
  return {
    ...state,
    ...INITIAL_STATE,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.SET_DATA]: setData,
  [Types.RESET_DATA]: resetData,
});
