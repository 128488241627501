import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cuid from 'cuid';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

import { Cancel as DeleteIcon } from '@material-ui/icons';

import { Creators as ComponentActions } from '~/store/modules/component/ducks';

import { Creators as QuestionItemActions } from '~/store/modules/questionItem/ducks';

import ComponentContext from '~/pages/Components/ComponentWizard/index.context';

import QuestionItemKind from './itemKinds';

import api from '~/services/api';

const RoundedPaper = styled(Paper)`
  border-radius: 5px !important;
`;

export default function QuestionsComponent() {
  const dispatch = useDispatch();
  const currentComponent = useSelector((state) => state.component.current);
  const { question } = currentComponent;
  const { items: itemsList } = question;
  const { saveComponent, isUpdating, updateComponent } = useContext(
    ComponentContext
  );
  const { pointEnabled } = useSelector((state) => state.conference.config);
  const defaultQuestionItem = {
    cuid: '',
    kind: '',
    title: '',
    point_value: 0,
    details: {},
  };

  const questionItemsKinds = [
    { label: 'Upload de Arquivo', kind: 'Evidence' },
    { label: 'Múltiplas Respostas', kind: 'MultiAnswer' },
    { label: 'Múltipla Escolha', kind: 'MultiChoice' },
    { label: 'Escolha Única', kind: 'SingleChoice' },
  ];

  const [questionItem, setQuestionItem] = useState({ ...defaultQuestionItem });

  const setItemData = ({ name, value }) => {
    setQuestionItem((state) => ({ ...state, [name]: value }));
  };

  const setChoicesData = (choices) => {
    setItemData({ name: 'details', value: { choices } });
  };

  const addQuestionItem = async () => {
    const newList = [...itemsList];

    try {
      if (isUpdating) {
        const { data } = await api.post('/questionItems', {
          ...questionItem,
          questionId: question.id,
        });

        newList.push({ ...questionItem, id: data.id });
      } else {
        questionItem.cuid = cuid();
        newList.push({ ...questionItem });
      }

      dispatch(
        ComponentActions.setComponentKindData({
          kind: 'question',
          name: 'items',
          value: newList,
        })
      );

      return setQuestionItem({ ...defaultQuestionItem });
    } catch (error) {
      return error;
    }
  };

  const removeQuestionItem = (questionItemId) => {
    let newList = [];

    if (isUpdating) {
      newList = itemsList.filter((item) => item.id !== questionItemId);
      dispatch(QuestionItemActions.destroyRequest({ id: questionItemId }));
    } else {
      newList = itemsList.filter((item) => item.cuid !== questionItemId);
    }

    dispatch(
      ComponentActions.setComponentKindData({
        kind: 'question',
        name: 'items',
        value: newList,
      })
    );
  };

  return (
    <Container>
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Grid container component={Box} spacing={1} pt={4} pb={2}>
          <Grid item xs={6} md={6}>
            <TextField
              name="title"
              variant="outlined"
              label="Pergunta"
              placeholder="Ex: Qual foi a palavra chave do evento?"
              InputLabelProps={{
                shrink: true,
              }}
              value={questionItem.title}
              onChange={({ target }) => setItemData(target)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              name="kind"
              variant="outlined"
              label="Tipo de Pergunta"
              placeholder="Selecione o tipo da pergunta"
              select
              InputLabelProps={{
                shrink: true,
              }}
              value={questionItem.kind}
              onChange={({ target }) => setItemData(target)}
              fullWidth
            >
              {questionItemsKinds.map((questionItemKind) => (
                <option
                  key={questionItemKind.kind}
                  value={questionItemKind.kind}
                >
                  {questionItemKind.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              disabled={!pointEnabled}
              name="point_value"
              variant="outlined"
              label="Pontuação"
              placeholder="Quantos pontos?"
              InputLabelProps={{
                shrink: true,
              }}
              value={questionItem.point_value}
              onChange={({ target }) => setItemData(target)}
              fullWidth
            />
          </Grid>
        </Grid>

        <QuestionItemKind
          kind={questionItem.kind}
          setChoices={setChoicesData}
        />

        <Box display="flex" justifyContent="center" pt={1}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={addQuestionItem}
            style={{ height: '55px' }}
          >
            Adicionar Pergunta
          </Button>
        </Box>
      </Box>

      <Box
        width={1}
        my={1}
        height={500}
        p={2}
        style={{ overflowY: 'auto', background: '#e8e8e8' }}
      >
        {!itemsList.length && (
          <Box width={1} textAlign="center">
            <Typography variant="h6">
              Nenhuma recurso criado até o momento
            </Typography>
          </Box>
        )}

        {!!itemsList.length && (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <Grid container spacing={1}>
              {itemsList.map((item) => (
                <Grid item xs={12} sm={6}>
                  <RoundedPaper variant="outlined">
                    <Box
                      display="flex"
                      alignItems="center"
                      width={1}
                      height={100}
                      p={2}
                      textOverflow="ellipsis"
                      position="relative"
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                          <Typography variant="h6">{item.title}</Typography>
                          <Typography variant="caption">
                            PONTUAÇÃO: {item.point_value}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} component={Box} textAlign="left">
                          <Chip label={item.kind} />
                        </Grid>
                        <Grid item xs={2} component={Box} textAlign="right">
                          <IconButton
                            onClick={() =>
                              removeQuestionItem(
                                isUpdating ? item.id : item.cuid
                              )
                            }
                            aria-label="delete"
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </RoundedPaper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>

      <Box mb={3} display="flex" justifyContent="flex-end">
        {isUpdating && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={updateComponent}
          >
            Atualizar Perguntas
          </Button>
        )}
        {!isUpdating && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={saveComponent}
          >
            Salvar Perguntas
          </Button>
        )}
      </Box>
    </Container>
  );
}
