import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Creators as ConferenceActions, Types as ConferenceTypes } from './ducks';

export function* getConferenceData() {
  try {
    const { data } = yield call(api.get, '/conferenceConfig');
    yield put(ConferenceActions.conferenceSuccess(data.conferenceConfig));
  } catch (err) {
    toast.error('Ocorreu um erro ao buscar as informações.');

    yield put(ConferenceActions.conferenceFailure(err.msg));
  }
}

export function* updateConferenceData({ payload }) {
  try {
    yield call(api.put, `/conferenceConfig/${payload.id}`, {
      instructions: payload.instructions,
      faq: payload.faq,
      pointEnabled: payload.pointEnabled,
      schedule: payload.schedule,
      scheduleEnabled: payload.scheduleEnabled,
    });
    yield put(ConferenceActions.conferenceSuccess(payload));
    toast.success('Dados atualizados com sucesso');
  } catch (err) {
    toast.error('Ocorreu um erro ao buscar as informações.');

    yield put(ConferenceActions.conferenceFailure(err.msg));
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(ConferenceTypes.CONFERENCE_REQUEST, getConferenceData),
    takeLatest(ConferenceTypes.CONFERENCE_UPDATE, updateConferenceData),
  ]);
}
