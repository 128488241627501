import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  config: {},
  loading: false,
  errors: [],
};

export const { Types, Creators } = createActions({
  conferenceRequest: [],
  conferenceUpdate: ['payload'],
  conferenceSuccess: ['payload'],
  conferenceFailure: ['errors'],
});

function conferenceRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function conferenceUpdate(state) {
  return {
    ...state,
    loading: true,
  };
}

function conferenceSuccess(state, { payload }) {
  return {
    ...state,
    config: payload,
    loading: false,
  };
}

function conferenceFailure(state, { errors }) {
  return {
    ...state,
    errors,
    loading: false,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.CONFERENCE_REQUEST]: conferenceRequest,
  [Types.CONFERENCE_UPDATE]: conferenceUpdate,
  [Types.CONFERENCE_SUCCESS]: conferenceSuccess,
  [Types.CONFERENCE_FAILURE]: conferenceFailure,
});
