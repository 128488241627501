import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().required('Por favor informe o nome.'),
  link: yup
    .string()
    .url('Informe uma url válida', { allowLocal: true })
    .required('Por favor informe a url'),
  tag_id: yup.string().required('Este campo é obrigatório.'),
});
