import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  Creators as LaunchPlanItemActions,
  Types as LaunchPlanItemTypes,
} from './ducks';
import { Creators as ComponentActions } from '~/store/modules/component/ducks';

export function* destroyLaunchPlanItem({ payload }) {
  try {
    yield call(api.delete, `/launchPlanItems/${payload.id}`);
    yield put(LaunchPlanItemActions.launchPlanItemSuccess());
    toast.success('Item deletado com sucesso');
  } catch (err) {
    yield put(LaunchPlanItemActions.launchPlanItemFailure(err.message));
    toast.error('Erro inesperado ao deletar o item');
  }
}

export function* addLaunchPlanItem({ payload }) {
  try {
    const { data } = yield call(api.post, `/launchPlanItems/`, payload.data);

    const { newList } = payload;
    newList.push(data);

    yield put(
      ComponentActions.setComponentKindData({
        kind: 'launch_plan',
        name: 'items',
        value: newList,
      })
    );
    yield put(LaunchPlanItemActions.launchPlanItemSuccess());
    toast.success('Item criado com sucesso');
  } catch (err) {
    yield put(LaunchPlanItemActions.launchPlanItemFailure(err.message));
    toast.error('Erro inesperado ao criar o item');
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(
      LaunchPlanItemTypes.LAUNCH_PLAN_ITEM_DESTROY_REQUEST,
      destroyLaunchPlanItem
    ),
    takeLatest(
      LaunchPlanItemTypes.LAUNCH_PLAN_ITEM_ADD_REQUEST,
      addLaunchPlanItem
    ),
  ]);
}
