import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  error: '',
};

export const { Types, Creators } = createActions({
  destroyRequest: ['payload'],
  questionItemSuccess: [],
  questionItemFailure: ['error'],
});

function destroyRequest(state) {
  return { ...state, loading: true };
}

function questionItemSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function questionItemFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.DESTROY_REQUEST]: destroyRequest,
  [Types.QUESTION_ITEM_SUCCESS]: questionItemSuccess,
  [Types.QUESTION_ITEM_FAILURE]: questionItemFailure,
});
