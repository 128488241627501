import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import { Box, Dialog, IconButton, Slide } from '@material-ui/core';
import { CloseOutlined as CloseIcon } from '@material-ui/icons';
import { Creators as ComponentActions } from '~/store/modules/component/ducks';
import WizardContext from './index.context';
import WelcomeStep from './steps/welcome';
import ComponentKindStep from './steps/kind';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ closeCallback }) {
  const dispatch = useDispatch();
  const currentComponent = useSelector((state) => state.component.current);
  const steps = ['welcome', 'componentKind'];
  const [currentStep, setCurrentStep] = useState('welcome');

  const handleClose = () => {
    dispatch(ComponentActions.resetComponent());
    closeCallback();
  };

  const handleNextStep = () => {
    const stepIndex = steps.indexOf(currentStep);
    if (stepIndex + 1 >= 0) {
      setCurrentStep(steps[stepIndex + 1]);
    }
  };

  const handlePrevStep = () => {
    const stepIndex = steps.indexOf(currentStep);
    if (stepIndex - 1 >= 0) {
      setCurrentStep(steps[stepIndex - 1]);
    }
  };

  const handleSaveComponent = () => {
    dispatch(ComponentActions.componentCreateRequest({ close: handleClose }));
  };

  const handleUpdateComponent = () => {
    dispatch(ComponentActions.componentUpdateRequest({ close: handleClose }));
  };

  const handleCreateNew = () => {
    dispatch(ComponentActions.resetComponent());
    setCurrentStep('welcome');
  };

  const handleInitialStep = () => {
    setCurrentStep('welcome');
  };

  return (
    <Dialog
      fullScreen
      open
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box display="flex" width={1} justifyContent="flex-end" p={2}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon size="24" />
        </IconButton>
      </Box>
      <Box>
        <WizardContext.Provider
          value={{
            currentStep,
            nextStep: handleNextStep,
            prevStep: handlePrevStep,
            saveComponent: handleSaveComponent,
            updateComponent: handleUpdateComponent,
            closeModal: handleClose,
            newComponent: handleCreateNew,
            toInitialStep: handleInitialStep,
            isUpdating: !!currentComponent.id,
          }}
        >
          {currentStep === 'welcome' && <WelcomeStep />}

          {currentStep === 'componentKind' && (
            <Slide direction="left" in mountOnEnter unmountOnExit>
              <div>
                <ComponentKindStep />
              </div>
            </Slide>
          )}
        </WizardContext.Provider>
      </Box>
    </Dialog>
  );
}

FullScreenDialog.propTypes = {
  closeCallback: Proptypes.func.isRequired,
};
