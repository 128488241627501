import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Creators as RankingActions, Types as RankingTypes } from './ducks';

export function* getRanking() {
  try {
    const { data } = yield call(api.get, '/ranking/top100');
    return yield put(RankingActions.top100Success(data));
  } catch ({ response }) {
    let error = 'Não foi buscar o top 100';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);

    return yield put(RankingActions.top100Failure(error));
  }
}

export function* getRankingScores({ participantId }) {
  try {
    const { data } = yield call(api.get, `/ranking/top100/${participantId}`);
    return yield put(RankingActions.top100ScoresSuccess(data));
  } catch ({ response }) {
    let error = 'Não foi buscar as pontuações do participante';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);

    return yield put(RankingActions.top100Failure(error));
  }
}

export function* getRankingScoresDetails({ options }) {
  try {
    const uri = Object.entries(options)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    const { data } = yield call(api.get, `/ranking/details?${uri}`);
    return yield put(RankingActions.top100ScoresDetailsSuccess(data));
  } catch ({ response }) {
    let error = 'Não foi buscar as pontuações do participante';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);

    return yield put(RankingActions.top100ScoresDetailsFailure(error));
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(RankingTypes.TOP100_REQUEST, getRanking),
    takeLatest(RankingTypes.TOP100_SCORES_REQUEST, getRankingScores),
    takeLatest(
      RankingTypes.TOP100_SCORES_DETAILS_REQUEST,
      getRankingScoresDetails
    ),
  ]);
}
