import React, { createRef, useState } from 'react';
import { toast } from 'react-toastify';
import Proptypes from 'prop-types';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { ReactComponent as UploadSvg } from '~/assets/svgs/upload.svg';

import { Creators as UsersActions } from '~/store/modules/users/ducks';

import StandardModal from '~/components/Modal';
import { FileInputDiv, StyledCenterBox } from './styles';
import importSchema from './import.schema';

export default function ImportUserDialog({ closeCallback }) {
  const fileInput = createRef();
  const [file, setFile] = useState(null);
  const tagsList = useSelector((state) => state.tag.list);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('adminEmail', values.email);
    formData.append('tagId', values.tag_id);
    formData.append('active', values.active);
    dispatch(UsersActions.userImportRequest(formData));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      tag_id: 1,
      active: true,
      file: false,
    },
    validationSchema: importSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleDivClick = () => {
    fileInput.current.click();
  };

  const handleChange = (event) => {
    const [uploadedFile] = event.target.files;

    if (!uploadedFile) {
      return false;
    }

    const fileType = uploadedFile.name.split('.').pop();

    if (fileType !== 'csv' || uploadedFile.size > 20 * 1024 * 1024) {
      return toast.error('Tipo de arquivo inválido ou tamanho maior que 20mb');
    }
    formik.setFieldValue('file', true, true);
    return setFile(uploadedFile);
  };

  const hasError = (fieldName) =>
    formik.touched[fieldName] && !!formik.errors[fieldName];

  return (
    <StandardModal
      title="Importar Lista de Participantes"
      onClose={closeCallback}
      isOpened
      maxWidth="sm"
      showDividers={false}
      slideTo="left"
      fullWidth
    >
      <>
        {formik.isSubmitting ? (
          <Grid container justify="center">
            <Typography>
              Sua solicitação foi enviada, assim que os dados forem processados,
              você receberá um email com o resultado da importação.
            </Typography>
            <StyledCenterBox mt={3}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                onClick={closeCallback}
              >
                Fechar
              </Button>
            </StyledCenterBox>
          </Grid>
        ) : (
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={12}>
              <Box mb={4}>
                <TextField
                  name="email"
                  variant="outlined"
                  label="Email para retorno"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={hasError('email')}
                  onBlur={formik.handleBlur}
                  helperText={hasError('email') ? formik.errors.email : null}
                  fullWidth
                />
              </Box>
              <Box mb={4}>
                <TextField
                  select
                  name="tag_id"
                  label="Selecione a Tag"
                  value={formik.values.tag_id}
                  onChange={formik.handleChange}
                  error={hasError('tag_id')}
                  onBlur={formik.handleBlur}
                  helperText={hasError('tag_id') ? formik.errors.tag_id : null}
                  variant="outlined"
                  fullWidth
                >
                  {!!tagsList.length &&
                    tagsList.map((tag) => (
                      <MenuItem value={tag.id}>{tag.name}</MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box mb={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.active}
                      onChange={formik.handleChange}
                      error={hasError('active')}
                      onBlur={formik.handleBlur}
                      helperText={
                        hasError('active') ? formik.errors.active : null
                      }
                      name="active"
                      color="primary"
                    />
                  }
                  label="Ativo"
                />
              </Box>
              <StyledCenterBox mb={2}>
                <FileInputDiv onClick={handleDivClick}>
                  <Grid container>
                    <Box p={1}>
                      <Grid item>
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="center"
                          mb={1}
                        >
                          <UploadSvg
                            style={{ height: '5.5rem', fill: '#d0d0d0' }}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="center"
                        >
                          <Typography
                            variant="body2"
                            align="center"
                            style={{
                              color: '#353535',
                              fontSize: '0.9rem',
                              fontWeight: 700,
                              lineHeight: '1.2',
                            }}
                          >
                            Faça o upload do seu arquivo csv contendo apenas as
                            colunas *name*, *email* e *belt* aqui
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </FileInputDiv>

                <input
                  ref={fileInput}
                  onChange={(e) => handleChange(e)}
                  type="file"
                  name="fileName"
                  hidden
                />
              </StyledCenterBox>
              <StyledCenterBox mb={4}>
                {file && (
                  <span style={{ fontSize: '0.9rem', lineHeight: '1' }}>
                    Arquivo anexado: <b>{file.name}</b>
                  </span>
                )}
              </StyledCenterBox>
              <StyledCenterBox mb={4}>
                {hasError('file') && (
                  <span
                    style={{
                      fontSize: '0.9rem',
                      lineHeight: '1',
                      color: 'red',
                    }}
                  >
                    {hasError('file') ? 'Arquivo é um campo obrigatório' : null}
                  </span>
                )}
              </StyledCenterBox>
              <StyledCenterBox>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={formik.handleSubmit}
                >
                  Importar
                </Button>
              </StyledCenterBox>
            </Grid>
          </Grid>
        )}
      </>
    </StandardModal>
  );
}

ImportUserDialog.propTypes = {
  closeCallback: Proptypes.func.isRequired,
};
