import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Creators as ConferenceActions } from '~/store/modules/conference/ducks';

export default function ConferenceConfigModal({ conferenceConfig }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.conference.loading);
  const [radioPointEnabled, setRadioPointEnabled] = useState('0');
  const [radioScheduleEnabled, setRadioScheduleEnabled] = useState('0');

  const [faq, setFaq] = useState(EditorState.createEmpty());
  const [instructions, setInstructions] = useState(EditorState.createEmpty());
  const [schedule, setSchedule] = useState(EditorState.createEmpty());
  const fields = {
    faq: {
      toHtml: () => draftToHtml(convertToRaw(faq.getCurrentContent())),
      setState: (content) => setFaq(content),
    },
    instructions: {
      toHtml: () => draftToHtml(convertToRaw(instructions.getCurrentContent())),
      setState: (content) => setInstructions(content),
    },
    schedule: {
      toHtml: () => draftToHtml(convertToRaw(schedule.getCurrentContent())),
      setState: (content) => setSchedule(content),
    },
  };

  const handleFieldChange = (field, content) => {
    fields[field].setState(content);
  };

  const handleChangePointEnabled = (e) => {
    const pointOnClick = e.target.value;
    setRadioPointEnabled(pointOnClick);
  };

  const handleChangeScheduleEnabled = (e) => {
    const pointOnClick = e.target.value;
    setRadioScheduleEnabled(pointOnClick);
  };

  const handleSubmit = () => {
    dispatch(
      ConferenceActions.conferenceUpdate({
        id: conferenceConfig.id,
        pointEnabled: radioPointEnabled === '1',
        scheduleEnabled: radioScheduleEnabled === '1',
        faq: fields.faq.toHtml(),
        instructions: fields.instructions.toHtml(),
        schedule: fields.schedule.toHtml(),
      })
    );
  };

  const setEditorContent = (key) => {
    const conferenceKeyValueString = conferenceConfig[key]
      ? conferenceConfig[key]
      : '';

    const conferenceKeyValue = htmlToDraft(conferenceKeyValueString);
    const editorState = ContentState.createFromBlockArray(
      conferenceKeyValue.contentBlocks
    );
    const content = EditorState.createWithContent(editorState);

    fields[key].setState(content);
  };

  useEffect(() => {
    if (conferenceConfig) {
      setRadioPointEnabled(conferenceConfig.pointEnabled ? '1' : '0');
      setRadioScheduleEnabled(conferenceConfig.scheduleEnabled ? '1' : '0');
      setEditorContent('instructions');
      setEditorContent('schedule');
      setEditorContent('faq');
    }
  }, []);

  return (
    <>
      <Grid container justify="center" spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <FormControl component="fieldset">
              <FormLabel component="legend">Ativar Pontuação ?</FormLabel>
              <RadioGroup
                name="radioPointEnabled"
                value={radioPointEnabled}
                onChange={(e) => handleChangePointEnabled(e)}
                row
              >
                <FormControlLabel value="1" control={<Radio />} label="Sim" />
                <FormControlLabel value="0" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <FormControl component="fieldset">
              <FormLabel component="legend">Mostrar Cronograma?</FormLabel>
              <RadioGroup
                name="scheduleEnabled"
                value={radioScheduleEnabled}
                onChange={(e) => handleChangeScheduleEnabled(e)}
                row
              >
                <FormControlLabel value="1" control={<Radio />} label="Sim" />
                <FormControlLabel value="0" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <Typography variant="body2">Cronograma:</Typography>
            <Editor
              wrapperClassName="text-editor-wrapper"
              editorClassName="text-editor-container"
              editorState={schedule}
              onEditorStateChange={(content) =>
                handleFieldChange('schedule', content)
              }
              toolbar={{
                options: [
                  'inline',
                  'list',
                  'textAlign',
                  'link',
                  'history',
                  'blockType',
                  'fontSize',
                  'image',
                ],
              }}
            />
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <Typography variant="body2">Instruções:</Typography>
            <Editor
              wrapperClassName="text-editor-wrapper"
              editorClassName="text-editor-container"
              editorState={instructions}
              onEditorStateChange={(content) =>
                handleFieldChange('instructions', content)
              }
              toolbar={{
                options: [
                  'inline',
                  'list',
                  'textAlign',
                  'link',
                  'history',
                  'blockType',
                  'fontSize',
                  'image',
                ],
              }}
            />
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <Typography variant="body2">FAQ:</Typography>
            <Editor
              wrapperClassName="text-editor-wrapper"
              editorClassName="text-editor-container"
              editorState={faq}
              onEditorStateChange={(content) =>
                handleFieldChange('faq', content)
              }
              toolbar={{
                options: [
                  'inline',
                  'list',
                  'textAlign',
                  'link',
                  'history',
                  'blockType',
                  'fontSize',
                  'image',
                ],
              }}
            />
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {!loading && 'Confirmar'}
              {loading && <CircularProgress size="22" />}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
ConferenceConfigModal.propTypes = {
  conferenceConfig: PropTypes.object,
};

ConferenceConfigModal.defaultProps = {
  conferenceConfig: { instructions: '', faq: '' },
};
