import React from 'react';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import StandardModal from '~/components/Modal';
import UserForm from './userForm';

export default function UpdateUserDialog({ closeCallback }) {
  const { params: userInfo } = useSelector((state) => state.modal);

  return (
    <StandardModal
      title="Atualizar Participante"
      onClose={closeCallback}
      isOpened
      maxWidth="sm"
      showDividers={false}
      slideTo="left"
      fullWidth
    >
      <Box
        width={1}
        className="h-100"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        pt={5}
      >
        <UserForm userInfo={userInfo} handleClose={closeCallback} />
      </Box>
    </StandardModal>
  );
}

UpdateUserDialog.propTypes = {
  closeCallback: Proptypes.func.isRequired,
};
