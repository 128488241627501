import styled from 'styled-components';
import { Box, TableContainer } from '@material-ui/core';

export const FileInputDiv = styled.div`
  background: #fff;
  width: 12rem;
  border: 2px dashed #abaaab;
  border-radius: 20px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledCenterBox = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const StyledTableContainer = styled(TableContainer)`
  background-color: white;
  padding-top: 5px;
`;
