import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import StandardModal from '~/components/Modal';

import { Creators as ModalActions } from '~/store/modules/modal/ducks';
import { Creators as UsersActions } from '~/store/modules/users/ducks';

const MODAL_NAME = 'DELETE_USER_CONFIRMATION';
export default function DestroyModal() {
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.modal.active);
  const user = useSelector((state) => state.modal.params);

  const closeModal = () => {
    dispatch(ModalActions.closeModal());
  };

  const handleDestroy = () => {
    dispatch(UsersActions.userDestroyRequest(user.id));
    closeModal();
  };

  return (
    <StandardModal
      onClose={closeModal}
      isOpened={openModal === MODAL_NAME}
      maxWidth="xs"
      showDividers={false}
      slideTo="left"
      fullWidth
    >
      <Grid container alignItems="center">
        <Box mt={3}>
          <Grid item xs={12}>
            <Typography component="span" variant="body1">
              Você está excluindo o usuário <strong>{user.name}</strong>, deseja
              prosseguir ?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} width={1} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleDestroy()}
              >
                Excluir
              </Button>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </StandardModal>
  );
}
