import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Por favor informe o nome.'),
  tags: Yup.array().min(1, 'Informe pelo menos uma tag'),
  kind: Yup.string().required('Informe o tipo de componente'),
  point_value: Yup.number().required('Por favor informe os pontos.'),
  point_on_click: Yup.boolean().required('Por favor informe esse campo.'),
  side_button: Yup.boolean().required('Por favor informe esse campo.'),
  point_day_limit: Yup.number().required(
    'Por favor informe a quantidade de pontuação diária.'
  ),
  point_conference_limit: Yup.number()
    .min(
      Yup.ref('point_day_limit'),
      'Precisa ser maior ou igual que a quantidade de pontuação diária'
    )
    .required('Por favor informe a quantidade de pontuação máxima.'),
});

export default schema;
