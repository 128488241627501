import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import StandardModal from '~/components/Modal';

import { Creators as ModalActions } from '~/store/modules/modal/ducks';
import { Creators as UsersActions } from '~/store/modules/users/ducks';

const MODAL_NAME = 'BULK_USER_CONFIRMATION';
export default function BulkUserTags({ clearUsersSelected }) {
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.modal.active);
  const { tag, users } = useSelector((state) => state.modal.params);

  const closeModal = () => {
    dispatch(ModalActions.closeModal());
  };

  const handleConfirm = () => {
    const usersToUpdate = users.map((user) => user.id);
    const form = {
      users: usersToUpdate,
      tag: tag.id,
    };
    dispatch(UsersActions.usersBulkTagChangeRequest(form));
    closeModal();
    clearUsersSelected();
  };

  return (
    <StandardModal
      onClose={closeModal}
      isOpened={openModal === MODAL_NAME}
      maxWidth="xs"
      showDividers={false}
      slideTo="left"
      fullWidth
    >
      <Grid container alignItems="center">
        <Box mt={3}>
          <Grid item xs={12}>
            <Typography component="span" variant="body1">
              Você deseja alterar todos esses usuários para a tag &nbsp;
              <strong>{tag.name}</strong> ?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} width={1} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleConfirm()}
              >
                Confirmar
              </Button>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </StandardModal>
  );
}
