import * as Yup from 'yup';

const schema = Yup.object().shape({
  pdf_button: Yup.boolean(),
  cta_link: Yup.string()
    .url('Informe uma url válida', { allowLocal: true })
    .when('pdf_button', {
      is: false,
      then: Yup.string().required('Por favor informe o link do botão.'),
    }),
  cta_text: Yup.string().required('Informe o texto do botão'),
  central: Yup.boolean().required('Informe esse campo'),
  icon: Yup.string().nullable(),
  content: Yup.string().max(255, 'Texto muito longo'),
});

export default schema;
