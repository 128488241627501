import * as Yup from 'yup';

const schema = Yup.object().shape({
  button_text: Yup.string().required('Informe o texto do botão'),
  button_fallback_link: Yup.string()
    .url('Informe uma url válida', { allowLocal: true })
    .required('Por favor informe o link de reserva.'),
});

export default schema;
