import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Box, Container, Avatar, Menu, MenuItem } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Creators as AuthActions } from '~/store/modules/auth/ducks';

import {
  AppBar,
  AvatarButton,
  AvatarName,
  ContainerImage,
  CustomLink,
} from './styles';

import logo from '~/assets/images/logo.png';
import { Creators as ConferenceActions } from '~/store/modules/conference/ducks';

export default function Header() {
  const admin = useSelector((state) => state.adminUser.profile);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const conferenceConfig = useSelector((state) => state.conference.config);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(AuthActions.signOut());
  };

  useEffect(() => {
    dispatch(ConferenceActions.conferenceRequest());
  }, []);

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="lg">
          <Box
            py={2}
            flex="1"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex">
              <ContainerImage>
                <img src={logo} alt="IGD Conf" width="100" />
              </ContainerImage>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              ml={2}
              justifyContent="center"
            >
              <CustomLink component={RouterLink} to="/">
                Componentes
              </CustomLink>
              <CustomLink component={RouterLink} to="/participants">
                Participantes
              </CustomLink>
              <CustomLink component={RouterLink} to="/rooms">
                Salas
              </CustomLink>
              <CustomLink component={RouterLink} to="/tags">
                Tags
              </CustomLink>
              {conferenceConfig.pointEnabled && (
                <CustomLink component={RouterLink} to="/ranking">
                  Ranking
                </CustomLink>
              )}
            </Box>

            <Box>
              <AvatarButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar alt={admin.name} />
                <AvatarName>{admin.name}</AvatarName>
              </AvatarButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSignOut}>Sair</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Container>
      </AppBar>
    </>
  );
}
