import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, CardContent, CardMedia, CircularProgress, TextField } from '@material-ui/core';
import { Creators as AuthActions } from '~/store/modules/auth/ducks';
import logo from '~/assets/images/logo.png';

import {
  Container,
  Text,
} from './styles';

function Signin() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const handleSignin = () => {
    dispatch(AuthActions.signInRequest(userData));
  };

  const handleSetData = ({ name, value }) => {
    setUserData(state => ({
      ...state,
      [name]: value
    }));
  };


  return (
    <Container>
      <Card style={{ maxWidth: '400px' }}>
        <CardMedia
          style={{ height: '100px' }}
          image={logo}
          title="brand logo"
        />
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Box p={3}>
              <TextField
                name="email"
                onChange={({ target }) => handleSetData(target)}
                variant="outlined"
                label="Email"
                placeholder="Digite seu email"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box p={3}>
              <TextField
                name="password"
                onChange={({ target }) => handleSetData(target)}
                variant="outlined"
                label="Senha"
                type="password"
                placeholder="Digite sua senha"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box display="flex" width={1} textAlign="center" justifyContent="center" alignItems="center" p={3}>
              <Button disabled={!!loading} onClick={handleSignin} fullWidth variant="contained" size="large" color="primary">
                Entrar &nbsp;
                {loading && <CircularProgress data-testid="loading" size={16} />}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Signin;
