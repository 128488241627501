import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  ListItem,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Close,
  DeleteForever as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import { Creators as ModalActions } from '~/store/modules/modal/ducks';
import { Creators as ComponentActions } from '~/store/modules/component/ducks';

import ComponentWizard from './ComponentWizard';
import DeleteComponentModal from '~/pages/Components/DeleteComponent';
import { Creators as TagsActions } from '~/store/modules/tag/ducks';
import EditConferenceConfig from './ConferenceConfigWizard';

export default function Components() {
  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.modal.active);
  const componentsList = useSelector((state) => state.component.list);
  const conferenceConfig = useSelector((state) => state.conference.config);
  const [editingConference, setEditingConference] = useState(false);
  const [tagFilter, setTagFilter] = useState(0);
  const tagsList = useSelector((state) => state.tag.list);
  const filteredComponentList = componentsList.filter((component) => {
    if (tagFilter) {
      const tagFind = component.tags.find(
        (tagItem) => tagItem.id === tagFilter
      );
      return tagFind;
    }
    return true;
  });

  const COMPONENT_WIZARD_NAME = 'COMPONENT_WIZARD';
  const DELETE_COMPONENT_MODAL = 'DELETE_COMPONENT';

  const handleComponentByTagListRequest = ({ value }) => {
    setTagFilter(value);
  };

  useEffect(() => {}, []);

  const handleOpenComponentWizard = () => {
    dispatch(ComponentActions.resetComponent());
    dispatch(ModalActions.openModal({ name: COMPONENT_WIZARD_NAME }));
  };

  const handleUpdateComponentWizard = (component) => {
    dispatch(ComponentActions.setCurrentComponent(component));
    dispatch(ComponentActions.fetchComponentKindData());
    dispatch(ModalActions.openModal(COMPONENT_WIZARD_NAME));
  };

  const handleCloseModal = () => {
    dispatch(ModalActions.closeModal());
  };

  const handleOpenDeleteComponentModal = (component) => {
    if (component.active) {
      toast.warning('Por favor desative o componente antes de excluí-lo');
      return;
    }
    dispatch(ComponentActions.setCurrentComponent(component));
    dispatch(ModalActions.openModal({ name: DELETE_COMPONENT_MODAL }));
  };

  const handleEditConference = () => {
    setEditingConference(!editingConference);
  };

  const toggleComponent = (componentId) => {
    dispatch(ComponentActions.toggleComponent(componentId));
  };

  useEffect(() => {
    dispatch(TagsActions.tagListRequest());
    dispatch(ComponentActions.fetchAllComponentsRequest());
  }, [dispatch]);

  return (
    <>
      {activeModal === DELETE_COMPONENT_MODAL && (
        <DeleteComponentModal closeCallback={handleCloseModal} />
      )}

      {activeModal === COMPONENT_WIZARD_NAME && (
        <ComponentWizard closeCallback={handleCloseModal} />
      )}

      <Container>
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8}>
                <Box display="flex" width={650} justifyContent="flex-start">
                  <Box width="35%">
                    <TextField
                      select
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="tag_id"
                      label="Selecione a Tag"
                      value={tagFilter}
                      onChange={({ target }) =>
                        handleComponentByTagListRequest(target)
                      }
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value={0}>Todas as Tags</MenuItem>
                      {!!tagsList.length &&
                        tagsList.map((tag) => (
                          <MenuItem key={tag.id} value={tag.id}>
                            {tag.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <Box
                  display="flex"
                  height="100%"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Box mr={1}>
                    <Button
                      onClick={() => handleOpenComponentWizard()}
                      variant="contained"
                      color="primary"
                      disableElevation
                    >
                      Criar Componente
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => handleEditConference()}
                      variant="contained"
                      color={editingConference ? 'secondary' : 'primary'}
                      disableElevation
                    >
                      {editingConference && <Close />} Editar Conferencia
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box my={2} display="flex" justifyContent="space-between">
              <Box
                display="flex"
                width={1}
                flexDirection="column"
                justifyContent="flex-start"
              >
                {!editingConference && (
                  <Paper
                    component={Box}
                    width={1}
                    mb={2}
                    flexDirection="column"
                  >
                    <Box
                      width={1}
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      {!!filteredComponentList.length &&
                        filteredComponentList.map((componentItem) => (
                          <ListItem key={componentItem.id} divider>
                            <Grid
                              container
                              spacing={1}
                              key={`component-${componentItem.id}`}
                            >
                              <Grid item xs={7}>
                                <Typography variant="h6">
                                  {componentItem.name}
                                </Typography>
                                {componentItem.tags.map((tag) => (
                                  <Chip
                                    key={tag.id}
                                    size="small"
                                    label={tag.name}
                                  />
                                ))}
                              </Grid>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={componentItem.active}
                                      onChange={() =>
                                        toggleComponent(componentItem.id)
                                      }
                                      name="active"
                                      color="primary"
                                    />
                                  }
                                  label="Exibir"
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <Box
                                  width={1}
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    onClick={() =>
                                      handleUpdateComponentWizard(componentItem)
                                    }
                                    size="small"
                                    startIcon={<EditIcon size="24" />}
                                  >
                                    Editar
                                  </Button>

                                  <Button
                                    onClick={() =>
                                      handleOpenDeleteComponentModal(
                                        componentItem
                                      )
                                    }
                                    size="small"
                                    startIcon={<DeleteIcon size="24" />}
                                  >
                                    Excluir
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItem>
                        ))}
                    </Box>
                  </Paper>
                )}
                {editingConference && (
                  <EditConferenceConfig
                    conferenceConfig={{
                      id: conferenceConfig.id,
                      faq: conferenceConfig.faq,
                      instructions: conferenceConfig.instructions,
                      pointEnabled: conferenceConfig.pointEnabled,
                      schedule: conferenceConfig.schedule,
                      scheduleEnabled: conferenceConfig.scheduleEnabled,
                    }}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
