import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  error: '',
  success: '',
};

export const { Types, Creators } = createActions({
  revokePointsRequest: ['request'],
  updatePointsRequest: ['request'],
  revokePointsSuccess: ['success'],
  revokePointsFailure: ['error'],
});

function revokePointsRequest(state) {
  return { ...state, loading: true };
}

function updatePointsRequest(state) {
  return { ...state, loading: true };
}

function revokePointsSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function revokePointsFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.REVOKE_POINTS_REQUEST]: revokePointsRequest,
  [Types.UPDATE_POINTS_REQUEST]: updatePointsRequest,
  [Types.REVOKE_POINTS_SUCCESS]: revokePointsSuccess,
  [Types.REVOKE_POINTS_FAILURE]: revokePointsFailure,
});
