import React from 'react';
import Proptypes from 'prop-types';
import { Box } from '@material-ui/core';

import StandardModal from '~/components/Modal';
import UserForm from './userForm';

export default function AddUserDialog({ closeCallback }) {
  return (
    <StandardModal
      title="Adicionar Participante"
      onClose={closeCallback}
      isOpened
      maxWidth="sm"
      showDividers={false}
      slideTo="left"
      fullWidth
    >
      <Box
        width={1}
        className="h-100"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        pt={5}
      >
        <UserForm handleClose={closeCallback} />
      </Box>
    </StandardModal>
  );
}

AddUserDialog.propTypes = {
  closeCallback: Proptypes.func.isRequired,
};
