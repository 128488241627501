import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Signin from '~/pages/Signin';
import Menu from '~/components/Menu';
import Users from '~/pages/Users';
import Components from '~/pages/Components';
import Ranking from '~/pages/Ranking';
import Rooms from '~/pages/Rooms';
import Tags from '~/pages/Tags';
import { store } from '~/store';

const ProtectedRoutes = ({ children }) => {
  const { signed } = store.getState().adminUser;

  if (!signed) {
    return <Redirect to="/login" />;
  }

  return children;
};

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" component={Signin} exact />
      <ProtectedRoutes>
        <Menu />
        <Route path="/" component={Components} exact />
        <Route path="/participants" component={Users} exact />
        <Route path="/rooms" component={Rooms} exact />
        <Route path="/ranking" component={Ranking} exact />
        <Route path="/tags" component={Tags} exact />
      </ProtectedRoutes>
    </Switch>
  );
}
