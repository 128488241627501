import * as yup from 'yup';

export default yup.object().shape({
  email: yup
    .string()
    .email('Email inválido')
    .required('Este campo é obrigatório.'),
  active: yup.string().required('Este campo é obrigatório.'),
  tag_id: yup.string().required('Este campo é obrigatório.'),
  file: yup
    .bool()
    .isTrue('Este campo é obrigatório.')
    .required('Este campo é obrigatório.'),
});
