import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, TextField } from '@material-ui/core';

import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'; // choose your lib
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from '@material-ui/pickers';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { Creators as ComponentActions } from '~/store/modules/component/ducks';

import WizardContext from '../../index.context';

export default function CountdownComponent() {
  const dispatch = useDispatch();
  const currentComponent = useSelector((state) => state.component.current);
  const { saveComponent, isUpdating, updateComponent } = useContext(
    WizardContext
  );

  const setData = ({ name, value }) => {
    dispatch(
      ComponentActions.setComponentKindData({ kind: 'countdown', name, value })
    );
  };

  const handleDateChangeEndTime = (value) => {
    setData({ name: 'end_time', value });
  };

  const handleDateChangeTitle = (value) => {
    setData({ name: 'title', value });
  };

  return (
    <Box
      className="h-100"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      pt={5}
    >
      <Container maxWidth="xs">
        <Box width={1} mb={4}>
          <TextField
            name="button_fallback_link"
            variant="outlined"
            label="Titulo"
            placeholder="O evento começa em:"
            InputLabelProps={{
              shrink: true,
            }}
            value={currentComponent.countdown.title}
            onChange={({ target }) => handleDateChangeTitle(target.value)}
            fullWidth
          />
        </Box>
        <Box mb={4}>
          <LocalizationProvider
            locale={ptBrLocale}
            dateAdapter={DateFnsAdapter}
          >
            <MobileDateTimePicker
              renderInput={(props) => (
                <TextField
                  variant="outlined"
                  {...props}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText=""
                />
              )}
              ampm={false}
              disablePast
              okText="Confirmar"
              cancelText="Cancelar"
              label="Data e Hora de Início?"
              value={currentComponent.countdown.end_time}
              onChange={handleDateChangeEndTime}
            />
          </LocalizationProvider>
        </Box>
        <Box mb={3} display="flex" justifyContent="center">
          {!!isUpdating && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              disabled={!currentComponent.countdown.end_time}
              onClick={updateComponent}
            >
              Atualizar
            </Button>
          )}
          {!isUpdating && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              disabled={!currentComponent.countdown.end_time}
              onClick={saveComponent}
            >
              Salvar
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
}
