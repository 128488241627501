import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Alert } from '@material-ui/lab';
import { useFormik } from 'formik';
import { Creators as ComponentActions } from '~/store/modules/component/ducks';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ComponentContext from '../../index.context';
import saleLetterSchema from './validation/saleLetter.schema';
import ColorPickerButton from '~/components/colorButton';

export default function SaleLetterComponent() {
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const currentComponent = useSelector((state) => state.component.current);
  const { saveComponent, isUpdating, updateComponent } = useContext(
    ComponentContext
  );

  const [buttonTextColor, setButtonTextColor] = useState('#000000');
  const [buttonIconColor, setButtonIconColor] = useState('#000000');
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState('#cccc');

  const setData = ({ name, value }) => {
    dispatch(
      ComponentActions.setComponentKindData({
        kind: 'sale_letter',
        name,
        value,
      })
    );
  };

  useEffect(() => {
    if (!buttonIconColor) {
      setButtonIconColor('#ffffff');
    }
  }, [buttonIconColor]);

  const formik = useFormik({
    initialValues: {
      text_color: currentComponent.sale_letter.text_color,
      icon_color: currentComponent.sale_letter.icon_color,
      background_color: currentComponent.sale_letter.background_color,
      pdf_button: currentComponent.pdf_button,
      cta_link: currentComponent.sale_letter.cta_link,
      icon: currentComponent.sale_letter.icon,
      content: currentComponent.sale_letter.content,
      cta_text: currentComponent.sale_letter.cta_text,
      central: false,
    },
    validationSchema: saleLetterSchema,
    onSubmit: (values) => {
      const entries = Object.entries(values);
      entries.forEach((item) => {
        const [name, value] = item;
        setData({ name, value });
      });
      if (isUpdating) {
        updateComponent();
      } else {
        saveComponent();
      }
    },
  });

  const handleEditorChange = (editorStateChange) => {
    const value = draftToHtml(
      convertToRaw(editorStateChange.getCurrentContent())
    );
    const name = 'content';
    formik.setFieldValue(name, value, true);
    setEditorState(editorStateChange);
  };

  useEffect(() => {
    const contentBlock = htmlToDraft(currentComponent.sale_letter.content);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }

    setButtonTextColor(currentComponent.sale_letter.text_color);
    setButtonIconColor(currentComponent.sale_letter.icon_color);
    setButtonBackgroundColor(currentComponent.sale_letter.background_color);

    formik.validateForm();
  }, []);

  const handleChangeButtonTextColor = (newColor) => {
    setButtonTextColor(newColor);
    formik.setFieldValue('text_color', newColor);
  };

  const handleChangeButtonIconColor = (newColor) => {
    setButtonIconColor(newColor);
    formik.setFieldValue('icon_color', newColor);
  };

  const handleChangeButtonBackgroundColor = (newColor) => {
    setButtonBackgroundColor(newColor);
    formik.setFieldValue('background_color', newColor);
  };

  return (
    <Container>
      {!currentComponent.pdf_button && (
        <Box width={1}>
          <Typography variant="h6">Conteúdo de Texto</Typography>
        </Box>
      )}
      {!currentComponent.pdf_button && (
        <Box mb={3}>
          <Editor
            wrapperClassName="text-editor-wrapper"
            editorClassName="text-editor-container"
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            toolbar={{
              options: [
                'inline',
                'list',
                'textAlign',
                'link',
                'history',
                'blockType',
                'fontSize',
                'image',
              ],
              // image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
            }}
          />
          {formik.errors.content && (
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
              {formik.errors.content}
            </p>
          )}
        </Box>
      )}

      {!currentComponent.pdf_button && (
        <Box width={1} mb={3}>
          <TextField
            name="cta_link"
            variant="outlined"
            label="Link do Botão"
            placeholder="http://example.com"
            InputLabelProps={{
              shrink: true,
            }}
            helperText={formik.errors.cta_link}
            error={formik.errors.cta_link}
            value={formik.values.cta_link}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
      )}
      <Box width={1} mb={3}>
        <Alert severity="info">
          Clique{' '}
          <Link
            href="https://material.io/resources/icons/?style=baseline"
            target="_blank"
            rel="noopener noreferrer"
          >
            aqui{' '}
          </Link>
          para consultar a lista de icones disponíveis!
        </Alert>
      </Box>
      <Box width={1} mb={3}>
        <TextField
          name="icon"
          variant="outlined"
          label="Icone"
          helperText={formik.errors.icon}
          error={formik.errors.icon}
          value={formik.values.icon}
          onChange={formik.handleChange}
          fullWidth
        />
      </Box>
      <Box width={1} mb={3}>
        <TextField
          data-testid="input-color"
          style={{ width: '100%' }}
          value={buttonTextColor}
          name="color"
          label="Cor do texto do botão"
          variant="outlined"
          placeholder="Ex: #FFF (Branca)"
          onChange={formik.handleChange}
          error={formik.errors.icon}
          helperText={formik.errors.icon}
          InputProps={{
            endAdornment: (
              <ColorPickerButton
                handleColorChange={handleChangeButtonTextColor}
                color={buttonTextColor}
              />
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box width={1} mb={3}>
        <TextField
          data-testid="input-color"
          style={{ width: '100%' }}
          value={buttonIconColor}
          name="color"
          label="Cor do icone do botão"
          variant="outlined"
          placeholder="Ex: #FFF (Branca)"
          onChange={formik.handleChange}
          error={formik.errors.icon}
          helperText={formik.errors.icon}
          InputProps={{
            endAdornment: (
              <ColorPickerButton
                handleColorChange={handleChangeButtonIconColor}
                color={buttonIconColor}
              />
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box width={1} mb={3}>
        <TextField
          data-testid="input-color"
          style={{ width: '100%' }}
          value={buttonBackgroundColor}
          name="color"
          label="Cor do botão"
          variant="outlined"
          placeholder="Ex: #FFF (Branca)"
          onChange={formik.handleChange}
          error={formik.errors.icon}
          helperText={formik.errors.icon}
          InputProps={{
            endAdornment: (
              <ColorPickerButton
                handleColorChange={handleChangeButtonBackgroundColor}
                color={buttonBackgroundColor}
              />
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box width={1} mb={3}>
        <TextField
          name="cta_text"
          variant="outlined"
          label="Texto do Botão"
          helperText={formik.errors.cta_text}
          error={formik.errors.cta_text}
          value={formik.values.cta_text}
          onChange={formik.handleChange}
          fullWidth
        />
      </Box>
      <Box mb={3} display="flex" justifyContent="flex-start">
        <Button
          variant="contained"
          size="large"
          color="primary"
          disabled={!formik.isValid}
          onClick={formik.submitForm}
        >
          {isUpdating ? 'Atualizar' : 'Salvar'}
        </Button>
      </Box>
    </Container>
  );
}
