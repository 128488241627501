import { createGlobalStyle, css } from 'styled-components';
import { lighten } from 'polished';

import 'react-toastify/dist/ReactToastify.css';

const calendarDay = css`
  background: ${props => lighten(0.3, props.theme.palette.primary.main)};
  border: 1px double ${props => lighten(0.2, props.theme.palette.primary.main)};
  color: ${props => props.theme.palette.primary.main};
`;

export default createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
    }

    html, body, #root {
     height: 100%;
    }

    body {
      font-family: 'Poppins', sans-serif;
      background: #F7F9FC;
    }

    .text-editor {
      &-container {
        height: 400px;
        border: 1px solid #eee;
        padding-left: 5px;
      }
    }

    .Toastify{
      &__toast{
        border-radius: 5px;

        &--info{
          background: #2196f3;
        }

        &--success {
          background: #4caf50;
        }

        &--warning {
          background: #ff9800;
        }

        &--error {
          background: #f44336;
        }
      }
    }

  .CalendarDay{
    &__selected{
      background: ${props => props.theme.palette.primary.main};
      border: 1px double ${props => props.theme.palette.primary.main};

      &_span, &_span:active, &_span:hover{
        ${calendarDay}
      }
    }

    &__selected:hover {
      background: ${props => props.theme.palette.primary.main};
      border: 1px double ${props => props.theme.palette.primary.main};
    }

    &__hovered{
      &_span, &_span:hover{
        ${calendarDay}
      }
    }
  }
`;
