import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  ListItem,
  Paper,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { GridList } from './styles';
import { Creators as TagsActions } from '~/store/modules/tag/ducks';
import { Creators as ModalActions } from '~/store/modules/modal/ducks';
import TagModal from './tagModal';

export default function Tags() {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '95%',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    actionGrid: {
      display: 'flex',
      'align-content': 'center',
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const TAG_MODAL = 'TAG_MODAL';

  const activeModal = useSelector((state) => state.modal.active);
  const tagsList = useSelector((state) => state.tag.list);

  const hasDefaultTag = tagsList.find((tag) => tag.is_default);

  const handleCloseModal = () => {
    dispatch(TagsActions.tagListRequest());
    dispatch(ModalActions.closeModal());
  };

  const handleOpenTagModal = (update) => {
    dispatch(
      ModalActions.openModal({
        name: TAG_MODAL,
        params: { callback: handleCloseModal, update },
      })
    );
  };

  useEffect(() => {
    dispatch(TagsActions.tagListRequest());
  }, []);

  return (
    <>
      {activeModal === TAG_MODAL && <TagModal hasDefaultTag={hasDefaultTag} />}

      <Container>
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box
              my={1}
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box>
                <Button
                  onClick={() => handleOpenTagModal()}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Criar Tag
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} mZd={12}>
            <Box
              display="flex"
              my={2}
              width={1}
              flexDirection="column"
              justifyContent="flex-start"
            >
              <Paper component={Box} width={1} mb={2} flexDirection="column">
                <Box
                  width={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  {!!tagsList.length &&
                    tagsList.map((tag) => (
                      <ListItem divider>
                        <GridList
                          container
                          spacing={1}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Grid item xs={2}>
                            <Typography>{tag.name}</Typography>
                            {tag.is_default && (
                              <Chip size="small" label="Padrão" />
                            )}
                          </Grid>

                          <Grid
                            item
                            display="flex"
                            alignItems="center"
                            xs={2}
                            className={classes.actionGrid}
                          >
                            <Box width={1}>
                              <Button
                                onClick={() => {
                                  handleOpenTagModal(tag);
                                }}
                                size="small"
                                startIcon={<EditIcon size="24" />}
                              >
                                Editar
                              </Button>
                            </Box>
                          </Grid>
                        </GridList>
                      </ListItem>
                    ))}
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
