import React, { useState, useMemo, useEffect } from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
  Radio,
} from '@material-ui/core';
import {
  Cancel as RemoveIcon,
  PlaylistAddOutlined as AddIcon,
} from '@material-ui/icons';
import cuid from 'cuid';

const CustomPaper = styled(Paper)`
  background-color: #faf8f8 !important;
  margin-top: 10px;
`;

function MultiTypeKind({ kind, setChoices }) {
  const [labels, setLabels] = useState([]);
  const [currentLabel, setCurrentLabel] = useState('');
  const hasLabels = useMemo(() => !!labels.length, [labels]);

  const setData = () => {
    setLabels((state) => [...state, { cuid: cuid(), label: currentLabel }]);
    setCurrentLabel('');
  };

  const removeLabel = (labelId) => {
    const newLabelList = labels.filter((label) => label.cuid !== labelId);
    setLabels(newLabelList);
  };

  useEffect(() => {
    setChoices(labels);
  }, [labels]);

  const kinds = {
    MultiAnswer: (item) => (
      <TextField
        name="choices"
        variant="outlined"
        label={item.label}
        value=""
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => removeLabel(item.cuid)}
                edge="end"
              >
                <RemoveIcon size="24" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    ),
    MultiChoice: (item) => (
      <Box width={1} display="flex">
        <FormControlLabel
          control={<Checkbox checked={false} />}
          label={item.label}
        />
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => removeLabel(item.cuid)}
          edge="end"
        >
          <RemoveIcon size="24" />
        </IconButton>
      </Box>
    ),
    SingleChoice: (item) => (
      <Box width={1} display="flex">
        <FormControlLabel
          control={<Radio checked={false} />}
          label={item.label}
        />
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => removeLabel(item.cuid)}
          edge="end"
        >
          <RemoveIcon size="24" />
        </IconButton>
      </Box>
    ),
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <TextField
            name="labelInput"
            variant="outlined"
            label="Input de Resposta"
            placeholder="Ex: Resposta 1."
            InputLabelProps={{
              shrink: true,
            }}
            value={currentLabel}
            onChange={({ target }) => setCurrentLabel(target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton aria-label="Add Choice" color="primary" onClick={setData}>
            <AddIcon size="44" />
          </IconButton>
        </Grid>
      </Grid>

      {hasLabels && (
        <CustomPaper variant="outlined">
          <Box p={2}>
            <Grid container spacing={1}>
              {labels.map((item) => (
                <Grid item xs={12} sm={4}>
                  {kinds[kind](item)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </CustomPaper>
      )}
    </Box>
  );
}

MultiTypeKind.propTypes = {
  setChoices: Proptypes.func.isRequired,
  kind: Proptypes.string,
};

MultiTypeKind.defaultProps = {
  kind: '',
};

export default MultiTypeKind;
